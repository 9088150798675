import React, { useState, useContext } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    InputAdornment,
    IconButton,
    Link,
    Divider
    /*Icon*/
    /*Avatar*/
} from '@material-ui/core';
import { 
    /*LockOutlined,*/ 
    Check, 
    Visibility, 
    VisibilityOff 
} from '@material-ui/icons';
import { makeStyles } from "@material-ui/core/styles";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import Copyright from "../Copyright";


const useStyles = makeStyles((theme) => ({
    main: {
        background: "#f1f1f1",
        padding: "20px 30px",
        border: "1px solid #dfdfdf",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.15)",
        borderRadius: "5px",
        marginTop: theme.spacing(8),
    },

    paper: {
        marginTop: theme.spacing(1),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    
    textField: {
        background: "#fff"
    },
    
    submit: {
        margin: theme.spacing(1, 0, 2),
        padding: "12px 12px",
        textTransform: "uppercase"
    },
    
    divider :{
        width: "100%",
        borderBottom: "1px solid #efefef",
        margin: "10px 20px"
    },

    registerLink: {
        clear: "both",
        float: "right",
        fontSize: "12px"
    }
}));

const Login = () => {
    const classes = useStyles();
    const [user, setUser] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const { handleLogin } = useContext(AuthContext);

    const handleChangeInput = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const handlSubmit = (e) => {
        e.preventDefault();
        handleLogin(user);
    };

    return (
        <Container className={classes.main} component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Box>
                    <img src="/assets/img/cmchat-logo-color-hr.svg" alt="" width="200" />
                </Box>
                
                <Divider className={classes.divider} />
                
                
                
                <form className={classes.form} noValidate onSubmit={handlSubmit}>

                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label={i18n.t("login.form.email")}
                        name="email"
                        value={user.email}
                        onChange={handleChangeInput}
                        autoComplete="email"
                        autoFocus
                    />

                    <TextField
                        className={classes.textField}
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={i18n.t("login.form.password")}
                        id="password"
                        value={user.password}
                        onChange={handleChangeInput}
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((e) => !e)}
                            >
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                            </InputAdornment>
                        )
                        }}
                    />

                    <Divider className={classes.divider} />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        size="small"
                        className={classes.submit}
                    >
                        {i18n.t("login.buttons.submit")} <Check />
                    </Button>

                    <Grid container>
                        <Grid item>
                            <Link
                                className={classes.registerLink}
                                href="#"
                                variant="body2"
                                component={RouterLink}
                                to="/signup"
                            >
                                {i18n.t("login.buttons.register")}
                            </Link>
                        </Grid>
                    </Grid>
                </form>
            </div>

            <Box mt={3}>{<Copyright />}</Box>
        </Container>
    );
};

export default Login;

import React, { useState, useEffect } from "react";
import Routes from "./routes";
import "react-toastify/dist/ReactToastify.css";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { ptBR } from "@material-ui/core/locale";

const App = () => {
    const [locale, setLocale] = useState();
    const theme = createTheme({
        overrides: {
            MuiContainer: {
                root: {
                    borderRadius: 3
                }
            },
            MuiButton: {
                root: {
                    borderRadius: 3
                }
            }
        },
        scrollbarStyles: {
            "&::-webkit-scrollbar": {
                width: "8px",
                height: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
                backgroundColor: "#e8e8e8",
            },
        },
        palette: {
            type: "light",
            primary: { 
                light: "#1d7293", 
                main: "#1d7293",
                dark: "#1d7293", 
            },
            secondary: { 
                light: "#981a00", 
                main: "#981a00",
                dark: "#981a00"  
            },
            info: {
                light: "#4f8da5",
                main: "#4f8da5",
                dark: "#4f8da5",
            },
            success: { 
                light: "#27b43d",
                main: "#27b43d",
                dark: "#27b43d", 
            },
            warning: { 
                light: "#eab226",
                main: "#eab226",
                dark: "#eab226",
            },
            error: {
                light: "#981a00",
                main: "#981a00",
                dark: "#981a00",
            },
            dark: {
                main: "#000"
            },
            light: {
                main: "#efefef"
            },
            text: {
                primary: "#666666",
                secondary: "#888888",
                disabled: "#cccccc",
                white: "#fff"
            },
        },

        background: {
            default: "#303030",
            paper: "#424242"
        },

        typography: {
            fontFamily: '"Open-Sans", Arial, Verdana, Helvetica',

            body1: {
                fontSize: "14px"
            },

            body2: {
                fontSize: "14px"
            },

            button: {
                textTransform: "normal"
            }
        },

        components: {
            MuiChip: {
                styleOverrides: {
                    colorPrimary: {
                        backgroundColor: "#1d7293",
                    },
                    colorSecondary: {
                        backgroundColor: "#4f8da5",
                    },
                    colorInfo: {
                        backgroundColor: "#4f8da5",
                    },
                    colorSuccess: {
                        backgroundColor: "#27b43d"
                    }
                },
            },
        },
    }, locale);

    useEffect(() => {
        const i18nlocale = localStorage.getItem("i18nextLng");
        const browserLocale = 
            i18nlocale.substring(0, 2) + i18nlocale.substring(3, 5);

        if (browserLocale === "ptBR") {
            setLocale(ptBR);
        }
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <Routes />
        </ThemeProvider>
    );
};

export default App;
import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
// import InputMask from 'react-input-mask';

// import { i18n } from "../../translate/i18n";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Popover
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    popover: {
        maxWidth: "50%",
        padding: "10px"
    },

    box: {
        margin: "10px"
    },

    typography: {
        padding: "0px 5px 10px",
        //maxHeight: "480px",
        //overflow: "auto"
    },

    pre: {
        //display: "block",
        //width: ""
        maxHeight: "490px",
        border: "1px solid #ccc",
        background: "#f9f9f9",
        overflow: "auto"
    },

    accordionDetails: {
        display: "block"
    },

    iconButton: {
        width: "16px",
        height: "16px",
        padding: "0px 0px",
        margin: "0px 0px",
        fontSize: "10px",
        marginTop: "-4px"
    },

    infoOutlineIcon: {
        label: {
            display: "block"
        },
        fontSize: "14px"
    }
}));


const ChatwootHelpPopover = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <span>
            <IconButton
                className={classes.iconButton}
                color="primary"
                size="small"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}>
                <InfoOutlineIcon className={classes.infoOutlineIcon} />
            </IconButton>
            <Popover className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.box}>
                    <h3>Templates de Variáveis para Respostas</h3>
                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        O aplicativo passa variáveis ​​para os modelos para manipulação. 
                        As variáveis ​​podem ter atributos ou elementos que
                        você também pode acessar. A representação visual de uma variável
                        depende muito da aplicação que a fornece.
                    </Typography>

                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        Use um ponto {"(.)"} para acessar atributos de uma variável {"("}métodos
                        ou propriedades de um objeto JSON ou itens de um array JSON{")"}:
                    </Typography>

                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        Exemplo da utilização: <strong>{"{{ chatwoot.status }}"}</strong>
                    </Typography>

                    <Accordion expanded={true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography className={classes.heading}>
                                <strong>Chatwoot {"{{ chatwoot }}"}</strong>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                Utilizando a ação do tipo resposta é possível utilizar pacote de dados 
                                recebido pelo Chatwoot.
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                Exemplo do pacote de dados vindos do Chatwoot:
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                <pre className={classes.pre}>{`{
    "additional_attributes": {},
    "can_reply": true,
    "channel": "Channel::Api",
    "contact_inbox": {
        "id": 10,
        "contact_id": 4,
        "inbox_id": 2,
        "source_id": "6a1ac476-a620-4169-a4b0-e40f57aeb721",
        "created_at": "2024-06-05T14:27:09.084Z",
        "updated_at": "2024-06-05T14:27:09.084Z",
        "hmac_verified": false,
        "pubsub_token": "ch9AbE9uvp7Vm9nwiHnUjoMF"
    },
    "id": 6,
    "inbox_id": 2,
    "messages": [
        {
            "id": 2246,
            "content": "**Fernando:**\\nTeste 1",
            "account_id": 3,
            "inbox_id": 2,
            "conversation_id": 6,
            "message_type": 0,
            "created_at": 1717681596,
            "updated_at": "2024-06-06T13:46:36.775Z",
            "private": false,
            "status": "sent",
            "source_id": "WAID:BAE5D71337283A69",
            "content_type": "text",
            "content_attributes": {},
            "sender_type": "Contact",
            "sender_id": 4,
            "external_source_ids": {},
            "additional_attributes": {},
            "processed_message_content": "**Fernando:**\\nTeste 1",
            "sentiment": {},
            "conversation": {
                "assignee_id": null,
                "unread_count": 2,
                "last_activity_at": 1717681596,
                "contact_inbox": {
                "source_id": "6a1ac476-a620-4169-a4b0-e40f57aeb721"
                }
            },
            "sender": {
                "additional_attributes": {},
                "custom_attributes": {},
                "email": null,
                "id": 4,
                "identifier": "551232098682@s.whatsapp.net",
                "name": "Clickmidia",
                "phone_number": "+551232098682",
                "thumbnail": "http://0.0.0.0:3000/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0f260d867d32580999462cf124adc072e57c6040/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RTNKbGMybDZaVjkwYjE5bWFXeHNXd2RwQWZvdyIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--6d67cc85ee1ef5607c0fd1f48989b596f125a667/184102099_343722144517852_7304428576912262372_n.jpg",
                "type": "contact"
            }
        }
    ],
    "labels": [],
    "meta": {
        "sender": {
        "additional_attributes": {},
        "custom_attributes": {},
        "email": null,
        "id": 4,
        "identifier": "551232098682@s.whatsapp.net",
        "name": "Clickmidia",
        "phone_number": "+551232098682",
        "thumbnail": "http://0.0.0.0:3000/rails/active_storage/representations/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBOZz09IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0f260d867d32580999462cf124adc072e57c6040/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaDdCem9MWm05eWJXRjBTU0lJYW5CbkJqb0dSVlE2RTNKbGMybDZaVjkwYjE5bWFXeHNXd2RwQWZvdyIsImV4cCI6bnVsbCwicHVyIjoidmFyaWF0aW9uIn19--6d67cc85ee1ef5607c0fd1f48989b596f125a667/184102099_343722144517852_7304428576912262372_n.jpg",
        "type": "contact"
        },
        "assignee": null,
        "team": null,
        "hmac_verified": false
    },
    "status": "open",
    "custom_attributes": {},
    "snoozed_until": null,
    "unread_count": 2,
    "first_reply_created_at": "2024-06-05T14:27:15.269Z",
    "priority": null,
    "waiting_since": 1717597645,
    "agent_last_seen_at": 1717598157,
    "contact_last_seen_at": 0,
    "timestamp": 1717681596,
    "created_at": 1717597629,
    "event": "automation_event.message_created"
}`}</pre>
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                Exemplo de utilização para resposta com dados do Chatwoot: 
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                {'{{'} chatwoot.meta.sender.email  {'}}'}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Popover>
        </span>
    );
};

export default ChatwootHelpPopover;
import React from "react";
import { Divider, makeStyles, Typography } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import PersonIcon from "@material-ui/icons/Person";

const useStyles = makeStyles((theme) => ({
    menuProfileWrap: {
        background: "#f1f1f1",
        padding: "10px 20px 30px"
    },

    profileIcon: {
        fontSize: "34px"
    },

    alignCenter: {
        textAlign: "center"
    },

    mgt: {
        marginTop: "20px"
    },

    strong: {
        fontWeight: "bold"
    }
})); 

const MenuItemProfile = ({ name,  email }) => {
	const classes = useStyles();

	return (
        <div className={classes.menuProfileWrap}>
            <div className={classes.alignCenter}>
                <PersonIcon className={classes.profileIcon} />
            </div>
            <Divider />
            <div className={classes.mgt}>
                <Typography>
                    <span className={classes.strong}>{i18n.t("mainDrawer.appBar.userProfile.labelName")}:</span> {name}
                </Typography>
                <Typography>
                    <span className={classes.strong}>{i18n.t("mainDrawer.appBar.userProfile.labelEmail")}:</span> {email}
                </Typography>
            </div>
        </div>
    );
};

export default MenuItemProfile;
import 
	React, { 
		useState, 
		useEffect, 
		// useContext 
	} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	CircularProgress,
	//Select,
	//InputLabel,
	//MenuItem,
	//FormControl,
	TextField,
	//InputAdornment,
	//IconButton,
	Grid
  } from '@material-ui/core';

//import { Visibility, VisibilityOff } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
//import QueueSelect from "../QueueSelect";
//import { AuthContext } from "../../context/Auth/AuthContext";
//import { Can } from "../Can";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

const EvolutionApiConnectionSchema = Yup.object().shape({
	instanceName: Yup.string()
			 .min(2, "Too Short!")
			 .max(100, "Too Long!")
			 .required("Required"),
	inboxId: Yup.number()
				.required('Required'),
	url: Yup.string()
			  .url("Invalid url")
			  .required("Required"),
	apiKey: Yup.string()
			  .min(2, "Too Short!")
			  .max(128, "Too Long!")
			  .required("Required"),
});

const EvolutionApiConnectionModal = ({ open, onClose, evolutionApiConnectionId }) => {
	const classes = useStyles();

	const initialState = {
		instanceName: "",
		inboxId: "",
		url: "",
		apiKey: ""
	};

	const [evolutionApiConnection, setEvolutionApiConnection] = useState(initialState);

	useEffect(() => {
		const fetchEvolutionApiConnection = async () => {
			if (!evolutionApiConnectionId) return;
			try {
				const { data } = await api.get(`/evolution-api-connections/${evolutionApiConnectionId}`);
				setEvolutionApiConnection(prevState => {
					return { ...prevState, ...data };
				});
				//const evolutionApiConnectionQueueIds = data.queues?.map(queue => queue.id);
				//setSelectedQueueIds(evolutionApiConnectionQueueIds);
			} catch (err) {
				toastError(err);
			}
		};

		fetchEvolutionApiConnection();
	}, [evolutionApiConnectionId, open]);

	const handleClose = () => {
		onClose();
		setEvolutionApiConnection(initialState);
	};

	const handleSaveEvolutionApiConnection = async values => {
		const evolutionApiConnectionData = { ...values };
		try {
			if (evolutionApiConnectionId) {
				await api.put(`/evolution-api-connections/${evolutionApiConnectionId}`, evolutionApiConnectionData);
			} else {
				await api.post("/evolution-api-connections", evolutionApiConnectionData);
			}
			toast.success(i18n.t("userModal.success"));
		} catch (err) {
			toastError(err);
		}
		handleClose();
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle id="form-dialog-title">
					{evolutionApiConnectionId
						? `${"Editar Evolution API"}`
						: `${"Adicionar Evolution API"}`}
				</DialogTitle>
				<Formik
					initialValues={evolutionApiConnection}
					enableReinitialize={true}
					validationSchema={EvolutionApiConnectionSchema}
					onSubmit={(values, actions) => {
						console.log(values);
						setTimeout(() => {
							handleSaveEvolutionApiConnection(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Grid container spacing={2}>
									<Grid item xs={6} md={4}>
										<Field
											as={TextField}
											label={"Nome da Instância"}
											autoFocus
											name="instanceName"
											error={touched.instanceName && Boolean(errors.instanceName)}
											helperText={touched.instanceName && errors.instanceName}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
									<Grid item xs={6} md={8}>
										<Field
											as={TextField}
											label={"URL"}
											autoFocus
											name="url"
											error={touched.url && Boolean(errors.url)}
											helperText={touched.url && errors.url}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
								</Grid>
								
								<Grid container spacing={2}>
									<Grid item xs={4} md={4}>
										<Field
											as={TextField}
											label={"Inbox ID"}
											autoFocus
											name="inboxId"
											error={touched.inboxId && Boolean(errors.inboxId)}
											helperText={touched.inboxId && errors.inboxId}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
									
									<Grid item xs={8} md={8}>
										<Field
											as={TextField}
											label={"API Key"}
											autoFocus
											name="apiKey"
											error={touched.apiKey && Boolean(errors.apiKey)}
											helperText={touched.apiKey && errors.apiKey}
											variant="outlined"
											margin="dense"
											fullWidth
										/>
									</Grid>
								</Grid>
									
									
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("userModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{evolutionApiConnectionId
										? `${i18n.t("userModal.buttons.okEdit")}`
										: `${i18n.t("userModal.buttons.okAdd")}`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default EvolutionApiConnectionModal;

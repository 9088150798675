import React, { useState, useEffect, useRef } from "react";

import * as Yup from "yup";
import { Formik, Form, Field, FieldArray } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import CircularProgress from "@material-ui/core/CircularProgress";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import DialpadIcon from "@material-ui/icons/Dialpad";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import LocalOfferOutlinedIcon from "@material-ui/icons/LocalOfferOutlined";
import InputMask from 'react-input-mask';
import ChatwootHelpPopover from "../ChatwootHelpPopover";
import CrmHelpPopover from "../CrmHelpPopover";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { 
	Divider, 
	FormControl,	 
	FormLabel,	 
	Grid, 
	IconButton,
	InputAdornment,  
	MenuItem, 
	Select, 
	useMediaQuery, 
	useTheme
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
		padding: "10px 10px",
	},

	formLabel: {
		marginBottom: "5px"
	},

	textField: {
		// fontSize: "14px",
		margin: "0px",
		marginRight: theme.spacing(1),
		flex: 1
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},

    formControl: {
        
    },

	operatorLogical: {
		color: "#1d7293"
	},

	formControlLogical: {
        
    },

	formControlDelete: {
		marginTop: "30px"
	},

	typographyConditional: {
		marginTop: "20px"
	},

	typographyActions: {
		marginTop: "20px"
	},

	dialogClose: {
		position: 'absolute',
		right: 8,
		top: 8,
		color: theme.palette.grey[500],
	},

	addFilters: {
		textAlign: "center",
		margin: "10px 0px 30px"
	},

	addActions: {
		textAlign: "center",
		margin: "10px 0px 30px"
	},

	dividers: {
		margin: "10px 0px 30px"
	},

	dialogActions: {
		paddingLeft: "30px",
		paddingRight: "30px",
	},

	flexBox: {
		display: "flex"
	},

	time: {
		border: "1px solid #ccc",
		borderRadius: "5px",
		padding: "10px",
	},

	fieldOrder: {
		display: "block",
		marginTop: "8px",
		marginBottom: "5px"
	}
}));

const AutomationBotSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	type: Yup.string().required("Invalid type"),
});

const AutomationBotModal = ({ open, onClose, automationId, initialValues, onSave }) => {
	const classes = useStyles();
	const isMounted = useRef(true);
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));
	const initialState = {
		name: "",
		type: "automation",
		status: 1,
		assistantId: "",
		time: new Date(),
	};
	//const [queue, setQueue] = useState(null);
	const [users, setUsers] = useState(null);
	// const [routers, setRouters] = useState(null);
	const [automationBot, setAutomationBot] = useState(initialState);
	const [type, setType] = useState("automation");


	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	useEffect(() => {
		const fetchAutomationBot = async () => {
			if (initialValues) {
				setAutomationBot(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!automationId) return;

			try {
				const { data } = await api.get(`/automation-bots/${automationId}`);
				if (isMounted.current) {
					setAutomationBot(data);
					setType(data.type);
				}
			} catch (err) {
				toastError(err);
			}
		};

		fetchAutomationBot();
	}, [automationId, open, initialValues]);

	useEffect(() => {
		const fetchUsers = async () => {
			try {
				const { data } = await api.get("/users/", {
					params: { 
						searchParam: "", 
						pageNumber: 1 
					},
				});
	
				setUsers(data);
			} catch (err) {
				toastError(err);
			}
		};

		if (!users) {
			fetchUsers();
		}
	}, [users]);

	const handleClose = () => {
		onClose();
		setAutomationBot(initialState);
	};

	/**
	 * 
	 * 
	 * @param {*} values 
	 */
	const handleSaveAutomationBot = async values => {
		let sendData = values;
		let conditions = sendData?.conditions;
		let actions = sendData?.actions;

		// for (let condition of conditions) {
		// 	if (condition.key == "condition-queue" && condition.value == "") {
		// 		condition.value = null;
		// 	}
		// }

		// for (let action of actions) {
		// 	if (action.key == "action-alter-ticket-queue" && action.value == "") {
		// 		action.value = null;
		// 	}
		// }

		sendData.conditions = conditions;
		sendData.actions = actions;
		
		try {
			if (automationId) {
				await api.put(`/automation-bots/${automationId}`, sendData);
				handleClose();
			} else {
				const { data } = await api.post("/automation-bots", sendData);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}
			toast.success(i18n.t("automationBotModal.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getPlaceholder = (condition) => {
		let placeholder = "";

		if (condition?.operator === "regexp" || condition?.operator === "not-regexp") {
			placeholder = "Ex.: /([a-z]|regex)/i";

		} else if (condition?.key === "custom") {
			placeholder = "Ex: {{ chatwoot.status }}";
		}

		return placeholder;
	};

	/**
	 * Start Adornment
	 * 
	 * @param {*} condition 
	 * @returns 
	 */
	const getStartAdornment = (condition) => {
		if (
			condition?.key === "phone-number" || 
			condition?.key === "restriction-phones" ||
			condition?.key === "blacklist-phones"
		) {
			return <InputAdornment position="start">
				<ContactPhoneIcon />
			</InputAdornment>
		
		} else if (condition.key === "condition-contact-email") {
			return <InputAdornment position="start">
				<ContactMailIcon />
			</InputAdornment>

		} else if (condition.key === "regexp") {
			return <InputAdornment position="start">
				<FilterCenterFocusIcon />
			</InputAdornment>

		} else if (condition.key === "condition-connection") {
			return <InputAdornment position="start">
				<DialpadIcon />
			</InputAdornment>

		} else if (condition.key === "label") {
			return <InputAdornment position="start">
				<LocalOfferOutlinedIcon />
			</InputAdornment>
		}

		return null;
	}

	/**
	 * End Adornment
	 * 
	 * @param {*} condition 
	 * @returns 
	 */
	const getEndAdornment = (condition) => {
		if (condition.key === "regexp") {
			return <InputAdornment position="end">
				
			</InputAdornment>
		} 
		
		return null;
	}

	/**
	 * Label action value
	 * 
	 * @param {*} condition 
	 * @returns 
	 */
	const getLabelFromActionValueField = (action) => {
		let label = "Valor";

		if (action.key === "response-ai") {
			label = "Assistente ID (A.I.)";

		} else if (action.key === "response") {
			label = "Resposta";

		} else if (action.key === "response-financial") {
			label = "Resposta Financeira";

		} else if (action.key === "webhook") {
			label = "Webhook";

		} else if (action.key === "contact-remove-custom-attributes") {
			label = "Nome do Atributo";
		}
		
		return label;
	}

	/**
	 * Row condition Logical
	 * 
	 * @param {*} props 
	 * @returns 
	 */
	const RowFilterLogical = (props) => {
		const condition = props.condition;
		const index = props.index;

		// Logical AND
		if (parseInt(index) > 1 && condition?.logical === "and") {
			return <FormControl
				variant="outlined secondary"
				className={classes.formControl}
				margin="dense"
				fullWidth={true}
			>
				<FormLabel className={classes.formLabel} id={`conditions-${index}-logical-selection-input-label`}>
					&nbsp;{/* {i18n.t("automationBotModal.conditional.conditionalLabel")} */}
				</FormLabel>
				<Field
					as={Select}
					name={`conditions[${index}].logical`}
					//label={i18n.t("automationBotModal.conditional.conditionalLabel")}
					variant="outlined"
					margin="dense"
					labelId={`conditions-${index}-logical-selection-input-label`}
					className={classes.operatorLogical}
					fullWidth={true}
					required
				>
					<MenuItem className={classes.operatorLogical} value="and">
						<strong>E</strong>
					</MenuItem>
					<MenuItem className={classes.operatorLogical} value="or">
						<strong>OU</strong>
					</MenuItem>
				</Field>
			</FormControl>

		// Logical OR
		} else if (parseInt(index) > 1 && condition?.logical === "or") {
			return <FormControl
				variant="outlined secondary"
				className={classes.formControl}
				margin="dense"
				fullWidth={true}
			>
				<FormLabel className={classes.formLabel} id={`conditions-${index}-logical-selection-input-label`}>
					&nbsp;{/* {i18n.t("automationBotModal.conditional.conditionalLabel")} */}
				</FormLabel>
				<Field
					as={Select}
					name={`conditions[${index}].logical`}
					//label={i18n.t("automationBotModal.conditional.conditionalLabel")}
					variant="outlined"
					margin="dense"
					labelId={`conditions-${index}-logical-selection-input-label`}
					className={classes.operatorLogical}
					fullWidth={true}
					required
				>
					<MenuItem className={classes.operatorLogical} value="or">
						<strong>OU</strong>
					</MenuItem>
					<MenuItem className={classes.operatorLogical} value="and">
						<strong>E</strong>
					</MenuItem>
				</Field>
			</FormControl>

		// Without Logical
		} else if (parseInt(index) === 0) {
			return <></>
		}

		// Default
		return <FormControl
			variant="outlined secondary"
			className={classes.formControlLogical}
			margin="dense"
			fullWidth={true}
		>
			<FormLabel className={classes.formLabel} id={`conditions-${index}-logical-selection-input-label`}>
				&nbsp;{/* {i18n.t("automationBotModal.conditional.conditionalLabel")} */}
			</FormLabel>
			<Field
				as={Select}
				name={`conditions[${index}].logical`}
				//label={i18n.t("automationBotModal.conditional.conditionalLabel")}
				variant="outlined"
				margin="dense"
				labelId={`conditions-${index}-logical-selection-input-label`}
				className={classes.operatorLogical}
				fullWidth={true}
				required
			>
				<MenuItem className={classes.operatorLogical} value="and">
					<strong>E</strong>
				</MenuItem>
				<MenuItem className={classes.operatorLogical} value="or">
					<strong>OU</strong>
				</MenuItem>
			</Field>
		</FormControl>
	};

	/**
	 * Row condition operator
	 * 
	 * @param {*} props 
	 * @returns 
	 */
	const RowConditionalOperator = (props) => {
		const condition = props.condition;
		const index = props.index;

		// Filter No recent Outgoing Message
		if (condition?.key === "group") {
			return <FormControl
				variant="outlined"
				className={classes.formControl}
				margin="dense"
				fullWidth={true}
			>
				<FormLabel className={classes.formLabel} id={`conditions-${index}-operator-selection-input-label`}>
					{i18n.t("automationBotModal.conditional.conditionalLabel")}
				</FormLabel>
				<Field
					as={Select}
					name={`conditions[${index}].operator`}
					//label={i18n.t("automationBotModal.conditional.conditionalLabel")}
					variant="outlined"
					margin="dense"
					labelId={`conditions-${index}-opertator-selection-input-label`}
					className={classes.textField}
					fullWidth={true}
				>
					<MenuItem value="equal">
						{i18n.t("automationBotModal.conditional.equal")}
					</MenuItem>
				</Field>
			</FormControl>

		// Filter Week Time Interval
		} else if (condition?.key === "week-time-interval") {
			return <FormControl
				variant="outlined"
				className={classes.formControl}
				margin="dense"
				fullWidth={true}
			>
				<FormLabel className={classes.formLabel} id={`conditions-${index}-conditional-selection-input-label`}>
					{i18n.t("automationModal.conditional.conditionalLabel")}
				</FormLabel>
				<Field
					as={Select}
					name={`conditions[${index}].operator`}
					//label={i18n.t("automationModal.conditional.conditionalLabel")}
					variant="outlined"
					margin="dense"
					labelId={`conditions-${index}-conditional-selection-input-label`}
					className={classes.textField}
					fullWidth={true}
				>
					<MenuItem value="between">
						{i18n.t("automationModal.conditional.between")}
					</MenuItem>
					<MenuItem value="not-between">
						{i18n.t("automationModal.conditional.notBetween")}
					</MenuItem>
				</Field>
			</FormControl>
		}

		return <FormControl
			variant="outlined"
			className={classes.formControl}
			margin="dense"
			fullWidth={true}
		>
			<FormLabel className={classes.formLabel} id={`conditions-${index}-operator-selection-input-label`}>
				{i18n.t("automationBotModal.conditional.conditionalLabel")}
			</FormLabel>
			<Field
				as={Select}
				name={`conditions[${index}].operator`}
				//label={i18n.t("automationBotModal.conditional.conditionalLabel")}
				variant="outlined"
				margin="dense"
				labelId={`conditions-${index}-operator-selection-input-label`}
				className={classes.textField}
				fullWidth={true}
			>
				<MenuItem value="equal">
					{i18n.t("automationBotModal.conditional.equal")}
				</MenuItem>
				<MenuItem value="not-equal">
					{i18n.t("automationBotModal.conditional.notEqual")}
				</MenuItem>
				<MenuItem value="contains">
					{i18n.t("automationBotModal.conditional.contain")}
				</MenuItem>
				<MenuItem value="not-contains">
					{i18n.t("automationBotModal.conditional.notContain")}
				</MenuItem>
				<MenuItem value="starts-with">
					{i18n.t("automationBotModal.conditional.startsWith")}
				</MenuItem>
				<MenuItem value="not-starts-with">
					{i18n.t("automationBotModal.conditional.notStartsWith")}
				</MenuItem>
				<MenuItem value="ends-with">
					{i18n.t("automationBotModal.conditional.endsWith")}
				</MenuItem>
				<MenuItem value="not-ends-with">
					{i18n.t("automationBotModal.conditional.notEndsWith")}
				</MenuItem>
				<MenuItem value="greater">
					{/* {i18n.t("automationBotModal.conditional.greater")} */}
					Maior Que
				</MenuItem>
				<MenuItem value="greater-equal">
					{/* {i18n.t("automationBotModal.conditional.greaterEqual")} */}
					Maior Igual
				</MenuItem>
				<MenuItem value="less">
					{/* {i18n.t("automationBotModal.conditional.less")} */}
					Menor Que
				</MenuItem>
				<MenuItem value="less-equal">
					{/* {i18n.t("automationBotModal.conditional.lessEqual")} */}
					Menor Igual
				</MenuItem>
				<MenuItem value="regexp">
					{i18n.t("automationBotModal.conditional.regexp")}
				</MenuItem>
				<MenuItem value="not-regexp">
					Não Regex
				</MenuItem>
				<MenuItem value="is-null">
					É Nulo
				</MenuItem>
				<MenuItem value="is-not-null">
					Não É Nulo
				</MenuItem>
				<MenuItem value="is-undefined">
					É Indefinido
				</MenuItem>
				<MenuItem value="is-not-undefined">
					Não É Indefinido
				</MenuItem>
			</Field>
		</FormControl>
	};


	/**
	 * Row condition value
	 * 
	 * @param {*} props 
	 * @returns 
	 */
	const RowConditionalValue = (props) => {
		const condition = props.condition;
		const index = props.index;

		// condition-body
		// condition-contact-name
		// condition-contact-email
		// condition-contact-number
		// condition-label
		if ((
			condition?.key === "remote-jid" ||
			condition?.key === "phone-number" ||
			condition?.key === "contact-name" ||
			condition?.key === "contact-email" ||
			condition?.key === "restriction-phones" ||
			condition?.key === "blacklist-phones" ||
			condition?.key === "label" ||
			condition?.key === "assignee-id" ||
			condition?.key === "assignee-name" ||
			condition?.key === "account-id" ||
			condition?.key === "conversation-id" ||
			condition?.key === "inbox-id" ||
			condition?.key === "message-content" ||
			condition?.key === "router-ai"
		) && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
					fullWidth={true}
				>
					<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
						{i18n.t("automationBotModal.conditional.valueLabel")}
					</FormLabel>
					<Field
						as={TextField}
						//label={i18n.t("automationBotModal.conditional.valueLabel")}
						name={`conditions[${index}].value`}
						variant="outlined"
						margin="dense"
						multiline
						minRows={1}
						maxRows={() => {
							return (condition?.key === "message-content") ? 5 : 1;
						}}
						className={classes.textField}
						labelId={`condition-selection-key-${index}`}
						fullWidth={true}
						placeholder={getPlaceholder(condition)}
						InputProps={{
							startAdornment: getStartAdornment(condition),
							endAdornment: getEndAdornment(condition),
						}}
					/>
				</FormControl>

		// messate-type immcoming or outgoing
		} else if (
			condition?.key === "message-type" && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
					fullWidth={true}
					required
				>
				<FormLabel className={classes.formLabel} id={`condition-value-${index}`}>
					{i18n.t("automationBotModal.conditional.valueLabel")}
				</FormLabel>
				<Field
					as={Select}
					//label={i18n.t("automationBotModal.conditional.valueLabel")}
					name={`conditions[${index}].value`}
					variant="outlined"
					margin="dense"
					labelId={`condition-value-${index}`}
					className={classes.textField}
					fullWidth={true}
				>
					<MenuItem value="">
						<em>---</em>
					</MenuItem>
					<MenuItem value="0">
						Entrada
					</MenuItem>
					<MenuItem value="1">
						Saida
					</MenuItem>
				</Field>
			</FormControl>
		
		} else if (
			condition?.key === "group" && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
					fullWidth={true}
					required
				>
				<FormLabel className={classes.formLabel} id={`condition-value-${index}`}>
					{i18n.t("automationBotModal.conditional.valueLabel")}
				</FormLabel>
				<Field
					as={Select}
					//label={i18n.t("automationBotModal.conditional.valueLabel")}
					name={`conditions[${index}].value`}
					variant="outlined"
					margin="dense"
					labelId={`condition-value-${index}`}
					className={classes.textField}
					fullWidth={true}
				>
					<MenuItem value="1">
						Sim, é um grupo
					</MenuItem>
					<MenuItem value="0">
						Não é um grupo 
					</MenuItem>
				</Field>
			</FormControl>
		
		// conversation status
		} else if (
			condition?.key === "conversation-status" && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
					fullWidth={true}
					required
				>
				<FormLabel className={classes.formLabel} id={`condition-value-${index}`}>
					{i18n.t("automationBotModal.conditional.valueLabel")}
				</FormLabel>
				<Field
					as={Select}
					//label={i18n.t("automationBotModal.conditional.valueLabel")}
					name={`conditions[${index}].value`}
					variant="outlined"
					margin="dense"
					labelId={`condition-value-${index}`}
					className={classes.textField}
					fullWidth={true}
				>
					<MenuItem value="open">
						Aberto
					</MenuItem>
					<MenuItem value="resolved">
						Resovido
					</MenuItem>
					<MenuItem value="pending">
						Pendente
					</MenuItem>
					<MenuItem value="snoozed">
						Aguardando
					</MenuItem>
				</Field>
			</FormControl>

		// week-time-interval
		} else if (
			condition?.key === "week-time-interval" && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <Grid container spacing={1}>
					<Grid item xs={6}>
						<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							required
							fullWidth={true}
						>
						<FormLabel className={classes.formLabel} id={`condition-value-${index}`}>
							{i18n.t("automationModal.conditional.weekLabel")}
						</FormLabel>
						<Field
							as={Select}
							//label={i18n.t("automationModal.conditional.valueLabel")}
							name={`conditions[${index}].value`}
							variant="outlined"
							margin="dense"
							labelId={`condition-value-${index}`}
							fullWidth={true}
						>
							<MenuItem value="0">
								{i18n.t("automationModal.conditional.week.sunday")}
							</MenuItem>
							<MenuItem value="1">
								{i18n.t("automationModal.conditional.week.monday")}
							</MenuItem>
							<MenuItem value="2">
								{i18n.t("automationModal.conditional.week.tuesday")}
							</MenuItem>
							<MenuItem value="3">
								{i18n.t("automationModal.conditional.week.wednesday")}
							</MenuItem>
							<MenuItem value="4">
								{i18n.t("automationModal.conditional.week.thursday")}
							</MenuItem>
							<MenuItem value="5">
								{i18n.t("automationModal.conditional.week.friday")}
							</MenuItem>
							<MenuItem value="6">
								{i18n.t("automationModal.conditional.week.saturday")}
							</MenuItem>
							<Divider />
							<MenuItem value="weekdays">
								{i18n.t("automationModal.conditional.week.weekdays")}
							</MenuItem>
							<MenuItem value="weekend">
								{i18n.t("automationModal.conditional.week.weekend")}
							</MenuItem>
							<Divider />
							<MenuItem value="any">
								{i18n.t("automationModal.conditional.week.any")}
							</MenuItem>
						</Field>
					</FormControl>
				</Grid>

				<Grid item xs={3}>
					<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth={true}
							required
						>
						<FormLabel className={classes.formLabel} id={`condition-from-time-${index}`}>
							{i18n.t("automationModal.conditional.fromTime")}
						</FormLabel>
						<Field
							as={InputMask}							
							//label={i18n.t("automationModal.conditional.fromTime")}
							name={`conditions[${index}].fromTime`}
							// variant="outlined"
							margin="dense"
							placeholder="08:00"
							className={classes.time}
							labelId={`conditionfrom-time-${index}`}
							mask="99:99">
						</Field>
					</FormControl>
				</Grid>

				<Grid item xs={3}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
						required
					>
						<FormLabel className={classes.formLabel} id={`condition-to-time-${index}`}>
							{i18n.t("automationModal.conditional.toTime")}
						</FormLabel>
							<Field
								as={InputMask}							
								//label={i18n.t("automationModal.conditional.toTime")}
								name={`conditions[${index}].toTime`}
								// variant="outlined"
								placeholder="12:00"
								margin="dense"
								className={classes.time}
								labelId={`condition-to-time-${index}`}
								mask="99:99"
								>
							</Field>
					</FormControl>
				</Grid>
			</Grid>

		// Custom Attributes | custom
		} else if ((
			condition?.key === "contact-custom-attributes" ||
			condition?.key === "contact-additional-attributes" ||
			condition?.key === "conversation-custom-attributes" ||
			condition?.key === "conversation-additional-attributes"
		) && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <Grid container spacing={1}>
				<Grid item xs={6}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
					>
						<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
							Nome do Atributo
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.conditional.valueLabel")}
							name={`conditions[${index}].attribute`}
							variant="outlined"
							margin="dense"
							multiline
							minRows={1}
							maxRows={() => {
								return (condition?.key === "message-content") ? 5 : 1;
							}}
							className={classes.textField}
							labelId={`condition-selection-key-${index}`}
							fullWidth={true}
							placeholder={getPlaceholder(condition)}
							InputProps={{
								startAdornment: getStartAdornment(condition),
								endAdornment: getEndAdornment(condition),
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
					>
						<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
							Valor
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.conditional.valueLabel")}
							name={`conditions[${index}].value`}
							variant="outlined"
							margin="dense"
							multiline
							minRows={1}
							maxRows={() => {
								return (condition?.key === "message-content") ? 5 : 1;
							}}
							className={classes.textField}
							labelId={`condition-selection-key-${index}`}
							fullWidth={true}
							placeholder={getPlaceholder(condition)}
							InputProps={{
								startAdornment: getStartAdornment(condition),
								endAdornment: getEndAdornment(condition),
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>
		
		} else if (
			condition?.key === "custom" && (
			condition.operator !== "" &&
			condition.operator !== "is-null" &&
			condition.operator !== "is-not-null" &&
			condition.operator !== "is-undefined" &&
			condition.operator !== "is-not-undefined"
		)) {
			return <Grid container spacing={1}>
				<Grid item xs={6}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
					>
						<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
							Chatwoot {"(Prop)"} <ChatwootHelpPopover />
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.conditional.valueLabel")}
							name={`conditions[${index}].attribute`}
							variant="outlined"
							margin="dense"
							multiline
							minRows={1}
							maxRows={() => {
								return (condition?.key === "message-content") ? 5 : 1;
							}}
							className={classes.textField}
							labelId={`condition-selection-key-${index}`}
							fullWidth={true}
							placeholder={getPlaceholder(condition)}
							InputProps={{
								startAdornment: getStartAdornment(condition),
								endAdornment: getEndAdornment(condition),
							}}
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
					>
						<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
							Valor
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.conditional.valueLabel")}
							name={`conditions[${index}].value`}
							variant="outlined"
							margin="dense"
							multiline
							minRows={1}
							maxRows={() => {
								return (condition?.key === "message-content") ? 5 : 1;
							}}
							className={classes.textField}
							labelId={`condition-selection-key-${index}`}
							fullWidth={true}
							placeholder={""}
							InputProps={{
								startAdornment: getStartAdornment(condition),
								endAdornment: getEndAdornment(condition),
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>
		} else if ((
			condition?.key === "custom" ||
			condition?.key === "contact-custom-attributes" ||
			condition?.key === "contact-additional-attributes" ||
			condition?.key === "conversation-custom-attributes" ||
			condition?.key === "conversation-additional-attributes"
		) && (
			condition.operator === "is-null" ||
			condition.operator === "is-not-null" ||
			condition.operator === "is-undefined" ||
			condition.operator === "is-not-undefined"
		)) {
			return <Grid container spacing={1}>
				<Grid item xs={12}>
					<FormControl
						variant="outlined"
						className={classes.formControl}
						margin="dense"
						fullWidth={true}
					>
						<FormLabel className={classes.formLabel} id={`condition-selection-name-${index}`}>
							Chatwoot {"(Prop)"} <ChatwootHelpPopover />
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.conditional.valueLabel")}
							name={`conditions[${index}].attribute`}
							variant="outlined"
							margin="dense"
							multiline
							minRows={1}
							maxRows={() => {
								return (condition?.key === "message-content") ? 5 : 1;
							}}
							className={classes.textField}
							labelId={`condition-selection-key-${index}`}
							fullWidth={true}
							placeholder={getPlaceholder(condition)}
							InputProps={{
								startAdornment: getStartAdornment(condition),
								endAdornment: getEndAdornment(condition),
							}}
						/>
					</FormControl>
				</Grid>
			</Grid>
		}

		return <></>
	};

	/**
	 * Row actions in modal.
	 * 
	 * @param {*} props 
	 * @returns 
	 */
	const RowActionValue = (props) => {
		const action = props.action;
		const index = props.index;

		// action-response
		// action-webhook
		// action-label-add
		// action-label-remove
		if (
			action?.key === "response" ||
			action?.key === "response-ai" ||
			action?.key === "response-financial" ||
			action?.key === "webhook" ||
			action?.key === "contact-remove-custom-attributes" ||
			action?.key === "contact-remove-additional-attributes" ||
			action?.key === "assignee-id" ||
			action?.key === "add-label" ||
			action?.key === "remove-label"
		) {
			return <FormControl
					variant="outlined"
					className={classes.formControl}
					margin="dense"
					fullWidth={true}
				>
				<FormLabel className={classes.formLabel} id={`action-value-${index}`}>
					{getLabelFromActionValueField(action)}

					{action?.key === "response" && <ChatwootHelpPopover />}
					{action?.key === "response-financial" && <CrmHelpPopover />}
				</FormLabel>
				<Field
					as={TextField}
					//label={i18n.t("automationBotModal.actions.valueLabel")}
					name={`actions[${index}].value`}
					variant="outlined"
					className={classes.textField}
					fullWidth={true}
					multiline
					maxRows={4}
					margin="dense"
				/>
			</FormControl>

		// router ai
		} else if (action?.key === "router-ai") {
			return <Grid container spacing={1}>
				<Grid item xs={6}>
					<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth={true}
						>
						<FormLabel className={classes.formLabel} id={`action-value-${index}`}>
							Assistente ID (A.I.)
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.actions.valueLabel")}
							name={`actions[${index}].value`}
							variant="outlined"
							className={classes.textField}
							fullWidth={true}
							multiline
							maxRows={4}
							margin="dense"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth={true}
						>
						<FormLabel className={classes.formLabel} id={`action-routers-${index}`}>
							Roteador(es)
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.actions.valueLabel")}
							name={`actions[${index}].routers`}
							variant="outlined"
							className={classes.textField}
							fullWidth={true}
							multiline
							maxRows={4}
							margin="dense"
						/>
					</FormControl>		
				</Grid>
			</Grid>

		// Add Custom Attributes
		} else if (
			action?.key === "contact-add-custom-attributes" ||
			action?.key === "contact-change-custom-attributes" ||
			action?.key === "contact-add-additional-attributes" ||
			action?.key === "contact-change-additional-attributes" ||
			action?.key === "custom"
		) {
			return <Grid container spacing={1}>
				<Grid item xs={6}>
					<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth={true}
						>
						<FormLabel className={classes.formLabel} id={`action-attribute-${index}`}>
							Nome do Atributo
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.actions.valueLabel")}
							name={`actions[${index}].attribute`}
							variant="outlined"
							className={classes.textField}
							fullWidth={true}
							multiline
							maxRows={4}
							margin="dense"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={6}>
					<FormControl
							variant="outlined"
							className={classes.formControl}
							margin="dense"
							fullWidth={true}
						>
						<FormLabel className={classes.formLabel} id={`action-value-${index}`}>
							Valor
						</FormLabel>
						<Field
							as={TextField}
							//label={i18n.t("automationBotModal.actions.valueLabel")}
							name={`actions[${index}].value`}
							variant="outlined"
							className={classes.textField}
							fullWidth={true}
							multiline
							maxRows={4}
							margin="dense"
						/>
					</FormControl>		
				</Grid>
			</Grid>
		}

		return <></>
	};

	return (
		<div className={classes.root}>
			<Dialog 
				fullScreen={fullScreen}
				fullWidth={true}
				maxWidth={"md"}
				open={open} 
				onClose={handleClose} 
				scroll="body"
			>
				<DialogTitle id="form-dialog-title" sx={{ m: 0, p: 2 }}>
					<IconButton
						className={classes.dialogClose}
						aria-label="close"
						onClick={onClose}
					>
						<CloseIcon />
					</IconButton>

					<AccountTreeOutlinedIcon fontSize="small" /> &nbsp;

					{automationId
						? `${i18n.t("automationBotModal.title.edit")}`
						: `${i18n.t("automationBotModal.title.add")}`}
				</DialogTitle>
				<Formik
					initialValues={automationBot}
					enableReinitialize={true}
					validationSchema={AutomationBotSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveAutomationBot(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers size="large" style={{ minWidth: "50%" }}>
								<Typography 
									color="primary"
									variant="subtitle1" 
									gutterBottom
								>
									{i18n.t("automationBotModal.form.mainInfo")}
								</Typography>

								<Grid container spacing={2}>
									<Grid item xs={12} md={6}>
										<FormControl
											variant="outlined"
											className={classes.formControl}
											margin="dense"
											fullWidth={true}
											required
										>
											<FormLabel className={classes.formLabel} id="type-selection-input-label">
												{i18n.t("automationBotModal.form.name")}
											</FormLabel>
											<Field
												as={TextField}
												//label={i18n.t("automationBotModal.form.name")}
												name="name"
												fullWidth={true}
												autoFocus
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												className={classes.textField}
												required
											/>
										</FormControl>
									</Grid>

									<Grid item xs={4} md={3}>
										<FormControl
											variant="outlined"
											className={classes.formControl}
											margin="dense"
											fullWidth={true}
											required
										>
											<FormLabel className={classes.formLabel} id="type-selection-input-label">
												{/* {i18n.t("automationBotModal.form.type")} */}
												Tipo
											</FormLabel>

											<Field
												as={Select}
												//label={i18n.t("webhooModal.form.type")}
												name="type"
												error={touched.type && Boolean(errors.type)}
												labelId="type-selection-label"
												id="type-selection"
												margin="dense"
												required
												onClick={(e) => {
													setType(e.target.value);
												}}
											>
												<MenuItem value="automation">
													{/* {i18n.t("automationBotModal.form.typeAutomation")} */}
													Automação
												</MenuItem>
												<MenuItem value="router">
													{/* {i18n.t("automationBotModal.form.typeRouter")} */}
													Roteador (IA)
												</MenuItem>
											</Field>
										</FormControl>
									</Grid>

									<Grid item xs={4} md={2}>
										<FormControl
											variant="outlined"
											className={classes.formControl}
											margin="dense"
											fullWidth={true}
											required
										>
											<FormLabel className={classes.formLabel} id="status-selection-input-label">
												{i18n.t("automationBotModal.form.status")}
											</FormLabel>

											<Field
												as={Select}
												//label={i18n.t("webhooModal.form.status")}
												name="status"
												labelId="status-selection-label"
												id="status-selection"
												required
											>
												<MenuItem value="1">
													{i18n.t("automationBotModal.form.statusEnabled")}
												</MenuItem>
												<MenuItem value="0">
													{i18n.t("automationBotModal.form.statusDisabled")}
												</MenuItem>
											</Field>
										</FormControl>
									</Grid>

									

									<Grid item xs={4} md={1}>
										<FormLabel className={classes.fieldOrder} id="status-selection-input-label">
											{i18n.t("automationBotModal.form.order")}
										</FormLabel>
										<Field
											as={TextField}
											//label={i18n.t("automationBotModal.form.order")}
											name="order"
											fullWidth={true}
											error={touched.order && Boolean(errors.order)}
											helperText={touched.order && errors.order}
											variant="outlined"
											margin="dense"
											className={classes.textField}
										/>
									</Grid>

								</Grid>

								<Divider className={classes.dividers} />

								<Typography 
									className={classes.typographyConditional} 
									variant="subtitle1" 
									gutterBottom
									color="primary"
								>
									{i18n.t("automationBotModal.conditional.title")}
								</Typography>

								{/* AutomationBot Filters */}
								<FieldArray name="conditions">
									{({ push, remove }) => (
										<>
											{values.conditions && values.conditions.length > 0 && values.conditions.map((info, index) => (
												<div
													className={classes.extraAttr}
													key={`${index}-info`}
												>

													<Grid container spacing={1} className="condition-row">

														<Grid item xs={1}>
															<RowFilterLogical condition={info} index={index} />
														</Grid>

														<Grid item xs={3}>
															<FormControl
																variant="outlined"
																className={classes.formControl}
																margin="dense"
																fullWidth={true}
															>
																<FormLabel className={classes.formLabel} id={`conditions-${index}-key-selection-input-label`}>
																	{i18n.t("automationBotModal.conditional.originLabel")}
																</FormLabel>
																
																<Field
																	as={Select}		
																	//label={i18n.t("automationBotModal.conditional.originLabel")}														
																	name={`conditions[${index}].key`}
																	variant="outlined"
																	margin="dense"
																	labelId={`conditions-${index}-key-selection-input-label`}
																	className={classes.textField}
																	required
																>
																	<MenuItem value="">
																		<em>---</em>
																	</MenuItem>
																	<MenuItem value="remote-jid">
																		Remote JID
																	</MenuItem>
																	<MenuItem value="phone-number">
																		Contato Telefone
																	</MenuItem>
																	<MenuItem value="contact-name">
																		Contato Nome
																	</MenuItem>
																	<MenuItem value="contact-email">
																		Contato Email
																	</MenuItem>
																	<MenuItem value="restriction-phones">
																		Restrição de Telefones
																	</MenuItem>
																	<MenuItem value="blacklist-phones">
																		Blacklist Telefones
																	</MenuItem>
																	<MenuItem value="group">
																		Grupo
																	</MenuItem>
																	<MenuItem value="contact-custom-attributes">
																		Atributos Customizado (Contato)
																	</MenuItem>
																	<MenuItem value="contact-additional-attributes">
																		Atributos Adicional (Contato)
																	</MenuItem>
																	<MenuItem value="conversation-custom-attributes">
																		Atributos Customizado (Conversa)
																	</MenuItem>
																	<MenuItem value="conversation-additional-attributes">
																		Atributos Adicional (Conversa)
																	</MenuItem>
																	<MenuItem value="label">
																		Tag
																	</MenuItem>
																	<MenuItem value="assignee-id">
																		Assinado Por ID
																	</MenuItem>
																	<MenuItem value="assignee-name">
																		Assinado Pelo Nome
																	</MenuItem>
																	<MenuItem value="account-id">
																		Conta ID
																	</MenuItem>
																	<MenuItem value="conversation-id">
																		Conversa ID
																	</MenuItem>
																	<MenuItem value="inbox-id">
																		Caixa de Entrada ID
																	</MenuItem>
																	<MenuItem value="conversation-status">
																		Conversa Status
																	</MenuItem>
																	<MenuItem value="message-content">
																		Mensagem
																	</MenuItem>
																	<MenuItem value="message-type">
																		{/* {i18n.t("automationBotModal.conditional.message")} */}
																		Tipo da Mensagem
																	</MenuItem>
																	<MenuItem value="week-time-interval">
																		{i18n.t("automationModal.conditional.weekTimeInterval")}
																	</MenuItem>
																	{type === "router" && <MenuItem value="router-ai">
																		Roteador (IA)
																	</MenuItem>}
																	<MenuItem value="custom">
																		Customizado
																	</MenuItem>
																</Field>
															</FormControl>
														</Grid>

														<Grid item xs={2}>
															<RowConditionalOperator 
																condition={info} 
																index={index} 
															/>
														</Grid>

														<Grid item xs={5}>
															<RowConditionalValue 
																condition={info} 
																index={index} 
															/>
														</Grid>
														
														<Grid item xs={1}>
															<FormControl
																variant="outlined"
																className={classes.formControlDelete}
																margin="dense"
															>
																<IconButton
																	color="secondary"
																	size="small"
																	onClick={() => remove(index)}
																>
																	<DeleteOutlineIcon />
																</IconButton>
															</FormControl>
														</Grid>
													</Grid>
												</div>
											))}

											<div className={classes.addFilters} style={{ textAlign: "center" }}>
												<Button 
													variant="contained"
													color="primary"
													size="small"
													onClick={() => push({ key: "", operator: "", value: "", logical: "and" })}
												>
													{`+ ${i18n.t("automationBotModal.buttons.addFilters")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>

								<Divider />

								<Typography 
									className={classes.typographyActions} 
									variant="subtitle1" 
									gutterBottom
									color="primary"
								>
									{i18n.t("automationBotModal.actions.title")} 
								</Typography>

								{/* AutomationBot Actions */}
								<FieldArray name="actions">
									{({ push, remove }) => (
										<>
											{values.actions && 
												values.actions.length > 0 && 
												values.actions.map((info, index) => (
													<div
														className={classes.extraAttr}
														key={`${index}-info`}
													>
														<Grid container spacing={1}>
															<Grid item xs={3}>
																<FormControl
																	variant="outlined"
																	className={classes.formControl}
																	margin="dense"
																	fullWidth={true}
																>
																	<FormLabel className={classes.formLabel} id={`action-${index}-key-selection-input-label`}>
																		{i18n.t("automationBotModal.actions.actionLabel")}
																	</FormLabel>
																	<Field
																		as={Select}
																		//label="Ação"
																		name={`actions[${index}].key`}
																		variant="outlined"
																		className={classes.textField}
																		labelId={`actions-${index}-key-selection-input-label`}
																		margin="dense"
																		fullWidth={true}
																	>
																		<MenuItem value="">
																			<em>---</em>
																		</MenuItem>
																		<MenuItem value="response">
																			Resposta
																		</MenuItem>
																		<MenuItem value="response-financial">
																			Resposta (Financeiro)
																		</MenuItem>
																		<MenuItem value="response-ai">
																			Resposta (I.A.)
																		</MenuItem>
																		<MenuItem value="router-ai">
																			Roteador (I.A.)
																		</MenuItem>
																		<MenuItem value="webhook">
																			Webhook
																		</MenuItem>
																		<MenuItem value="assignee-id">
																			Atribuir Usuário
																		</MenuItem>
																		<Divider />
																		<MenuItem value="add-label">
																			Adicionar Tag
																		</MenuItem>
																		{/* <MenuItem value="remove-label">
																			Remover Tag
																		</MenuItem> */}
																		<Divider />
																		<MenuItem value="contact-add-custom-attributes">
																			{"Adicionar Atributo Customizado (Contato)"}
																		</MenuItem>
																		<MenuItem value="contact-change-custom-attributes">
																			{"Alterar Atributo Customizado (Contato)"}
																		</MenuItem>
																		<MenuItem value="contact-remove-custom-attributes">
																			{"Remover Atributo Customizado (Contato)"}
																		</MenuItem>
																		<Divider />
																		<MenuItem value="contact-add-additional-attributes">
																			{"Adicionar Atributo Adicional (Contato)"}
																		</MenuItem>
																		<MenuItem value="contact-change-additional-attributes">
																			{"Alterar Atributo Adicional (Contato)"}
																		</MenuItem>
																		<MenuItem value="contact-remove-additional-attributes">
																			{"Remover Atributo Adicional (Contato)"}
																		</MenuItem>
																	</Field>
																</FormControl>
															</Grid>

															<Grid item xs={8}>
																<RowActionValue 
																	action={info} 
																	index={index} 
																/>
															</Grid>
														
															<Grid item xs={1}>
																<FormControl
																	variant="outlined"
																	className={classes.formControlDelete}
																	margin="dense"
																>
																	<IconButton
																		color="secondary"
																		size="small"
																		onClick={() => remove(index)}
																	>
																		<DeleteOutlineIcon />
																	</IconButton>
																</FormControl>
															</Grid>
														</Grid>
													</div>
												)
											)}
											<div className={classes.addActions}>
												<Button
													variant="contained"
													color="primary"
													size="small"
													onClick={() => push({ key: "", value: "" })}
												>
													{`+ ${i18n.t("automationBotModal.buttons.addActions")}`}
												</Button>
											</div>
										</>
									)}
								</FieldArray>
							</DialogContent>
							<DialogActions
								className={classes.dialogActions}>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
									startIcon={<CloseIcon />}
								>
									{i18n.t("automationBotModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
									startIcon={<CheckIcon />}
								>
									Salvar
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default AutomationBotModal;
const messages = {
  pt: {
    translations: {
      signup: {
        title: "Cadastre-se",
        toasts: {
          success: "Usuário criado com sucesso! Faça seu login!!!.",
          fail: "Erro ao criar usuário. Verifique os dados informados.",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Cadastrar",
          login: "Já tem uma conta? Entre!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Senha",
        },
        buttons: {
          submit: "Entrar",
          register: "Não tem um conta? Cadastre-se!",
        },
      },
      auth: {
        toasts: {
          success: "Login efetuado com sucesso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoje: ",
          },
        },
        messages: {
          inAttendance: {
            title: "Em Atendimento"
          },
          waiting: {
            title: "Aguardando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexões",
        toasts: {
          deleted: "Conexão com o WhatsApp excluída com sucesso!",
        },
        confirmationModal: {
          deleteTitle: "Deletar",
          deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
          disconnectTitle: "Desconectar",
          disconnectMessage:
            "Tem certeza? Você precisará ler o QR Code novamente.",
        },
        buttons: {
          add: "Adicionar WhatsApp",
          disconnect: "desconectar",
          tryAgain: "Tentar novamente",
          qrcode: "QR CODE",
          newQr: "Novo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "Falha ao iniciar sessão do WhatsApp",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code",
          },
          qrcode: {
            title: "Esperando leitura do QR Code",
            content:
              "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão",
          },
          connected: {
            title: "Conexão estabelecida!",
          },
          timeout: {
            title: "A conexão com o celular foi perdida",
            content:
              "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code",
          },
        },
        table: {
          name: "Nome",
          status: "Status",
          lastUpdate: "Última atualização",
          default: "Padrão",
          actions: "Ações",
          session: "Sessão",
        },
      },
      whatsappModal: {
        title: {
          add: "Adicionar WhatsApp",
          edit: "Editar WhatsApp",
          farewellMessage: "Messagem de Despedida"
        },
        form: {
          name: "Nome",
          default: "Padrão",
          farewellMessage: "Mensagem de despedida"
        },
        tab: {
          basic: "Básico",
          queue: "Filas",
          messages: "Mensagens"
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "WhatsApp salvo com sucesso.",
      },
      whatsappConnectionsSelect: {
        inputLabel: "Importar tickets da conexão",
        info: "O campo acima só deve ser utilizado quando houver necessidade de importação de tickets para a conexão que está sendo manipulada!",
        noConnection: "Sem conexão"
      },
      qrCode: {
        message: "Leia o QrCode para iniciar a sessão",
      },
      contacts: {
        title: "Contatos",
        toasts: {
          deleted: "Contato excluído com sucesso!",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle: "Deletar ",
          importTitlte: "Importar contatos",
          deleteMessage:
            "Tem certeza que deseja deletar este contato? Todos os tickets relacionados serão perdidos.",
        },
        buttons: {
          import: "Importar Contatos",
          add: "Adicionar Contato",
        },
        table: {
          name: "Nome",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Ações",
        },
      },
      contactModal: {
        title: {
          add: "Adicionar contato",
          edit: "Editar contato",
        },
        form: {
          mainInfo: "Dados do contato",
          extraInfo: "Informações adicionais",
          name: "Nome",
          number: "Número do Whatsapp",
          email: "Email",
          extraName: "Nome do campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Adicionar informação",
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Contato salvo com sucesso.",
      },
      terms: {
        title: "Termo",
        toasts: {
          deleted: "Termo eliminado com sucesso!",
        },
        searchPlaceholder: "Pesquisar ...",
        confirmationModal: {
          deleteTitle: "Remover",
          deleteMessage:
            "Deseja eliminar esta Tag? Todos as tags relacionadas serão perdidos.",
        },
        buttons: {
          add: "Adicionar Termo",
        },
        table: {
          name: "Nome",
          slug: "Slug",
          taxonomy: "Taxonomia",
          description: "Descrição",
          count: "Contador",
          actions: "Ações",
        },
      },
      termModal: {
        title: {
          add: "Adicionar Termo",
          edit: "Editar Termo",
        },
        form: {
          mainInfo: "Detalles do Termo",
          name: "Nome",
          slug: "Slug",
          taxonomy: "Taxonomia",
          description: "Descrição",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Termo guardado com sucesso",
      },
      quickAnswersModal: {
        title: {
          add: "Adicionar Resposta Rápida",
          edit: "Editar Resposta Rápida",
        },
        form: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Resposta Rápida salva com sucesso.",
      },
      queueModal: {
        title: {
          add: "Adicionar fila",
          edit: "Editar fila",
        },
        form: {
          name: "Nome",
          color: "Cor",
          greetingMessage: "Mensagem de saudação",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Adicionar usuário",
          edit: "Editar usuário",
        },
        form: {
          name: "Nome",
          email: "Email",
          password: "Senha",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Adicionar",
          okEdit: "Salvar",
          cancel: "Cancelar",
        },
        success: "Usuário salvo com sucesso.",
      },
      chat: {
        noTicketMessage: "Selecione um ticket para começar a conversar.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Novo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Filas",
      },
      tickets: {
        toasts: {
          deleted: "O ticket que você estava foi deletado.",
        },
        notification: {
          message: "Mensagem de",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolvidos" },
          search: { title: "Busca" },
        },
        search: {
          placeholder: "Buscar tickets e mensagens",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Digite para buscar usuários",
        fieldQueueLabel: "Transferir para fila",
        fieldQueuePlaceholder: "Selecione uma fila",
        noOptions: "Nenhum usuário encontrado com esse nome",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Aguardando",
        assignedHeader: "Atendendo",
        noTicketsTitle: "Nada aqui!",
        noTicketsMessage:
          "Nenhum ticket encontrado com esse status ou termo pesquisado",
        buttons: {
          accept: "Aceitar",
          view: "Abrir"
        },
      },
      newTicketModal: {
        title: "Criar Ticket",
        fieldLabel: "Digite para pesquisar o contato",
        add: "Adicionar",
        buttons: {
          ok: "Salvar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexões",
          tickets: "Atendimentos",
          contacts: "Contatos",
          quickAnswers: "Respostas Rápidas",
          queues: "Filas",
          administration: "Administração",
          users: "Usuários",
          settings: "Configurações",
          cmcImportMessages: "Importar Mensagens",
          schedulerMessages: "Messagens Agendadas",
          apiHelp: "API",
          webhooks: "Webhooks",
          automations: "Automação",
          terms: "Tags"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Sair",
          },

          userProfile: {
            labelName: "Nome",
            labelEmail: "Email"
          }
        },
      },
      notifications: {
        noTickets: "Nenhuma notificação.",
      },
      queues: {
        title: "Filas",
        table: {
          name: "Nome",
          color: "Cor",
          greeting: "Mensagem de saudação",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar fila",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Você tem certeza? Essa ação não pode ser revertida! Os tickets dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída.",
        },
      },
      queueSelect: {
        inputLabel: "Filas",
      },
      quickAnswers: {
        title: "Respostas Rápidas",
        table: {
          shortcut: "Atalho",
          message: "Resposta Rápida",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar Resposta Rápida",
        },
        toasts: {
          deleted: "Resposta Rápida excluída com sucesso.",
        },
        searchPlaceholder: "Pesquisar...",
        confirmationModal: {
          deleteTitle:
            "Você tem certeza que quer excluir esta Resposta Rápida: ",
          deleteMessage: "Esta ação não pode ser revertida.",
        },
      },
      users: {
        title: "Usuários",
        table: {
          name: "Nome",
          email: "Email",
          profile: "Perfil",
          actions: "Ações",
        },
        buttons: {
          add: "Adicionar usuário",
        },
        toasts: {
          deleted: "Usuário excluído com sucesso.",
        },
        confirmationModal: {
          deleteTitle: "Excluir",
          deleteMessage:
            "Todos os dados do usuário serão perdidos. Os tickets abertos deste usuário serão movidos para a fila.",
        },
      },
      settings: {
        success: "Configurações salvas com sucesso.",
        title: "Configurações",
        settings: {
          userCreation: {
            name: "Criação de usuário",
            options: {
              enabled: "Ativado",
              disabled: "Desativado",
            },
          },
          keepHistory: {
            name: "Manter histórico",
            options: {
              enabled: "Ativo",
              disabled: "Desativado",
            },
          },
          apiToken: "Api Token",
          mainUrl: "URL Principal",
          templateAutomationActionSendMessageToNumber: "Template de Automatização(Envio de Mensagem para o número)"
        },
      },
      messagesList: {
        header: {
          assignedTo: "Atribuído à:",
          buttons: {
            return: "Retornar",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceitar",
            tags: "Tags",
            additionalItems: "Adicionais"
          },
          chip: {
            widthoutQueue: "Sem Fila"
          }
        },
      },
      messagesInput: {
        placeholderOpen: "Digite uma mensagem ou tecle ''/'' para utilizar as respostas rápidas cadastrada",
        placeholderClosed:
          "Reabra ou aceite esse ticket para enviar uma mensagem.",
        signMessage: "Assinar",
      },
      contactDrawer: {
        header: "Dados do contato",
        buttons: {
          edit: "Editar contato",
        },
        extraInfo: "Outras informações",
      },
      ticketOptionsMenu: {
        delete: "Remover",
        transfer: "Transferir",
        markAsUnread: "Marcar Como Não Lido",
        confirmationModal: {
          title: "Deletar o ticket do contato",
          message: "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas.",
        },
        unreadMessagesModal: {
          title: "Marcar como ticket não lido #",
          message: "O ticket será marcado como não lido.",
        },
        buttons: {
          delete: "Excluir",
          cancel: "Cancelar",
        },
        importMessages: "Importar Mensagens",
        schedulerMessages: "Agendar Mensagens",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      unreadMessagesModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Deletar",
        reply: "Responder",
        confirmationModal: {
          title: "Apagar mensagem?",
          message: "Esta ação não pode ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
        ERR_NO_DEF_WAPP_FOUND:
          "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
        ERR_WAPP_CHECK_CONTACT:
          "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
        ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
        ERR_INVALID_CREDENTIALS:
          "Erro de autenticação. Por favor, tente novamente.",
        ERR_SENDING_WAPP_MSG:
          "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
        ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
        ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
        ERR_USER_CREATION_DISABLED:
          "A criação do usuário foi desabilitada pelo administrador.",
        ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
        ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
        ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
        ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
        ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
        ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
        ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
        ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
        ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
        ERR_FETCH_WAPP_MSG:
          "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Esta cor já está em uso, escolha outra.",
        ERR_WAPP_GREETING_REQUIRED:
          "A mensagem de saudação é obrigatório quando há mais de uma fila.",
      },

      apiHelp: {
        title: "API - Ajuda",
        apiTitle: "API - Ajuda"
      },

      webhooks: {

        confirmationModal: {
            deleteTitle: "Remover Webhook ",
            deleteMessage: "Deseja remover o webhook "
        },

        searchPlaceholder: "Pesquisar ...",

        table: {
          name: "Nome",
          url: "URL",
          trigger: "Acionar",
          method: "Método",
          status: "Status",
          actions: "Ações"
        }
      },

      webhookModal: {
        title: { 
          edit: "Editar webhook",
          add: "Adicionar webhook"
        },

        form: {
          mainInfo: "Detalhes do webhook",
          name: "Nome",
          url: "URL",
          trigger: "Disparar (Hook)",
          triggerMessageFromMe: "Mensagem de Saida",
          triggerMessageNotFromMe: "Mensagem de Entrada",
          status: "Status",
          statusEnabled: "Ativo",
          statusDisabled: "Inativo"
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Adicionar",
          okEdit: "Editar"
        }
      },

      automations: {
        title: "Automações",

        confirmationModal: {
          deleteTitle: "Remover automação ",
          deleteMessage: "Gostaria de remover a automação"
        },

        searchPlaceholder: "Pesquisar ...",

        table: {
          name: "Nome",
          url: "URL",
          trigger: "Acionar",
          order: "Ordenar",
          status: "Status",
          actions: "Ações"
        },

        toasts: {
          deleted: "Automação removida!"
        }
      },

      automationBots: {
        title: "Automações",

        confirmationModal: {
          deleteTitle: "Remover automação ",
          deleteMessage: "Gostaria de remover a automação"
        },

        searchPlaceholder: "Pesquisar ...",

        table: {
          name: "Nome",
          url: "URL",
          trigger: "Acionar",
          order: "Ordenar",
          status: "Status",
          actions: "Ações"
        },

        toasts: {
          deleted: "Automação removida!"
        }
      },

      automationModal: {
        title: { 
          edit: "Editar automação",
          add: "Adicionar automação"
        },

        form: {
          mainInfo: "Detalhes da Automação",
          name: "Nome",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Mensagem de Saída",
          triggerMessageNotFromMe: "Mensagem de Entrada",
          status: "Status",
          statusEnabled: "Habilitado",
          statusDisabled: "Desabilitado",
          order: "Ordenar"
        },

        buttons: {
          addFilters: "Adicionar Filtro",
          addActions: "Adicionar Ação",
          cancel: "Cancelar",
          okAdd: "Adicionar",
          okEdit: "Editar"
        },

        conditional: {
          title: "Condicionais",
          originLabel: "Origem",
          contentMessage: "Conteudo da Mensagem",
          contactName: "Nome do Contado",
          contactEmail: "Email do do Contato",
          contactNumber: "Número de contato",
          ticketQueue: "Fila do Ticket",
          ticketStatus: "Status do Ticket",
          widthoutQueue: "Sem Fila",
          connection: "Conexão",

          conditionalLabel: "Condicional",
          equal: "Igual",
          notEqual: "Não Igual",
          contain: "Contêm",
          notContain: "Não Contêm",
          startsWith: "Começa Com",
          endsWith: "Termina Com",
          notStartsWith: "Não Começa Com",
          notEndsWith: "Não Termina Com",
          regexp: "Regex",

          valueLabel: "Valor",

          noRecentOutgoingMessage: "Sem mensagem recente de saida (2 min)",

          active: "Active",

          tags: "Tags",

          weekLabel: "Semana",
          weekTimeInterval: "Semana / Intervalo Hora",
          between: "No Intervalo",
          notBetween: "Fora do Intervalo",
          fromTime: "De",
          toTime: "Até",

          week: {
            sunday: "Domingo",
            monday: "Segunda",
            tuesday: "Terça",
            wednesday: "Quarta",
            thursday: "Quinta",
            friday: "Sexta",
            saturday: "Sábado",
            weekdays: "Días Úteis (Seg..Sex)",
            weekend: "Final de Semana (Sab..Dom)",
            any: "Qualquer"
          }
        },

        actions: {
          title: "Ações",
          actionLabel: "Ação",
          responseMessage: "Mensagem de Resposta Message",
          alterQueue: "Alterar Fila",
          alterOperator: "Alterar Operador",
          alterStatus: "Alterar Status",
          webhook: "Webhook",
          valueLabel: "Valor",
          widthoutQueue: "Widthout Fila",
          addTag: "Adicionar Tag",
          removeTag: "Remover Tag",
          sendMessageToNumber: "Enviar Mensagem Para o Número"
        },

        status: {
          opened: "Aberto",
          pending: "Pendente",
          closed: "Resolvido"
        },

        success: "Automação registrada com sucesso!"
      },

      automationBotModal: {
        title: { 
          edit: "Editar automação",
          add: "Adicionar automação"
        },

        form: {
          mainInfo: "Detalhes da Automação",
          name: "Nome",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Mensagem de Saída",
          triggerMessageNotFromMe: "Mensagem de Entrada",
          status: "Status",
          statusEnabled: "Habilitado",
          statusDisabled: "Desabilitado",
          order: "Ordenar"
        },

        buttons: {
          addFilters: "Adicionar Filtro",
          addActions: "Adicionar Ação",
          cancel: "Cancelar",
          okAdd: "Adicionar",
          okEdit: "Editar"
        },

        conditional: {
          title: "Condicionais",
          originLabel: "Origem",
          contentMessage: "Conteudo da Mensagem",
          contactName: "Nome do Contado",
          contactEmail: "Email do do Contato",
          contactNumber: "Número de contato",
          ticketQueue: "Fila do Ticket",
          ticketStatus: "Status do Ticket",
          widthoutQueue: "Sem Fila",
          connection: "Conexão",

          conditionalLabel: "Condicional",
          equal: "Igual",
          notEqual: "Não Igual",
          contain: "Contêm",
          notContain: "Não Contêm",
          startsWith: "Começa Com",
          endsWith: "Termina Com",
          notStartsWith: "Não Começa Com",
          notEndsWith: "Não Termina Com",
          regexp: "Regex",

          valueLabel: "Valor",

          noRecentOutgoingMessage: "Sem mensagem recente de saida (2 min)",

          active: "Active",

          tags: "Tags",

          weekLabel: "Semana",
          weekTimeInterval: "Semana / Intervalo Hora",
          between: "No Intervalo",
          notBetween: "Fora do Intervalo",
          fromTime: "De",
          toTime: "Até",

          week: {
            sunday: "Domingo",
            monday: "Segunda",
            tuesday: "Terça",
            wednesday: "Quarta",
            thursday: "Quinta",
            friday: "Sexta",
            saturday: "Sábado",
            weekdays: "Días Úteis (Seg..Sex)",
            weekend: "Final de Semana (Sab..Dom)",
            any: "Qualquer"
          }
        },

        actions: {
          title: "Ações",
          actionLabel: "Ação",
          responseMessage: "Mensagem de Resposta Message",
          alterQueue: "Alterar Fila",
          alterOperator: "Alterar Operador",
          alterStatus: "Alterar Status",
          webhook: "Webhook",
          valueLabel: "Valor",
          widthoutQueue: "Widthout Fila",
          addTag: "Adicionar Tag",
          removeTag: "Remover Tag",
          sendMessageToNumber: "Enviar Mensagem Para o Número"
        },

        status: {
          opened: "Aberto",
          pending: "Pendente",
          closed: "Resolvido"
        },

        success: "Automação registrada com sucesso!"
      },

      schedulerMessages: {
        buttons: {
          add: "Adicionar Mensagens Agendadas"
        },

        confirmationModal: {
          deleteTitle: "Remover Mensagem Agendada",
          deleteMessage: "Gostaria de remover a mensagem agendada?"
        },

        searchPlaceholder: "Pesquisar ...",

        table: {
          title: "Mensagens Agendadas",
          broadcast: "Broadcast",
          body: "Mensagem",
          mediaType: "Tipo de Mídia",
          scheduledTo: "Agendado",
          createdAt: "Criado em",
          status: "Status",
          statusWaiting: "Aguardando",
          statusSent: "Enviado",
          actions: "Ações"
        },

        toasts: {
          deleted: "Horário removido!"
        }
      },

      schedulerMessagesModal: {
        title: { 
          edit: "Editar Mensagens do Agendador",
          add: "Adicionar Mensagens do Agendador"
        },

        form: {
          body: "Entre com a mensagem aqui ...",
          scheduledTo: "Agendar Para: ",
          scheduledToDate: "Agendar Data: ",
          scheduledToTime: "Agendar Hora: "
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Adicionar",
          okEdit: "Editar"
        },

        success: "Successo ao registrar a mensagem!"
      },

      notesList: {
        notFound: "Nenhuma nota existente para o ticket",
        cancel: "Cancelar",
        submit: "Enviar",
        buildContent: {
          placeholder: "Digite a nota ..."
        },
        linkHelp: "Manual de ajuda para linguagem markdown."
      },
    },
  },
};

export { messages };

const messages = {
  en: {
    translations: {
      signup: {
        title: "Sign up",
        toasts: {
          success: "User created successfully! Please login!",
          fail: "Error creating user. Check the reported data.",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Register",
          login: "Already have an account? Log in!",
        },
      },
      login: {
        title: "Login",
        form: {
          email: "Email",
          password: "Password",
        },
        buttons: {
          submit: "Enter",
          register: "Don't have an account? Register!",
        },
      },
      auth: {
        toasts: {
          success: "Login successfully!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets today: ",
          },
        },
        messages: {
          inAttendance: {
            title: "In Service"
          },
          waiting: {
            title: "Waiting"
          },
          closed: {
            title: "Closed"
          }
        }
      },
      connections: {
        title: "Connections",
        toasts: {
          deleted: "WhatsApp connection deleted sucessfully!",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage: "Are you sure? It cannot be reverted.",
          disconnectTitle: "Disconnect",
          disconnectMessage: "Are you sure? You'll need to read QR Code again.",
        },
        buttons: {
          add: "Add WhatsApp",
          disconnect: "Disconnect",
          tryAgain: "Try Again",
          qrcode: "QR CODE",
          newQr: "New QR CODE",
          connecting: "Connectiing",
        },
        toolTips: {
          disconnected: {
            title: "Failed to start WhatsApp session",
            content:
              "Make sure your cell phone is connected to the internet and try again, or request a new QR Code",
          },
          qrcode: {
            title: "Waiting for QR Code read",
            content:
              "Click on 'QR CODE' button and read the QR Code with your cell phone to start session",
          },
          connected: {
            title: "Connection established",
          },
          timeout: {
            title: "Connection with cell phone has been lost",
            content:
              "Make sure your cell phone is connected to the internet and WhatsApp is open, or click on 'Disconnect' button to get a new QRcode",
          },
        },
        table: {
          name: "Name",
          status: "Status",
          lastUpdate: "Last Update",
          default: "Default",
          actions: "Actions",
          session: "Session",
        },
      },
      whatsappModal: {
        title: {
          add: "Add WhatsApp",
          edit: "Edit WhatsApp",
        },
        form: {
          name: "Name",
          default: "Default",
          farewellMessage: "Farewell Message"
        },
        tab: {
          basic: "Basic",
          queue: "Queue",
          messages: "Messages"
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "WhatsApp saved successfully.",
      },
      whatsappConnectionsSelect: {
        inputLabel: "Import tickets from connection",
        info: "The field above should only be used when there is a need to import tickets for the connection being manipulated!",
        noConnection: "No connection"
      },
      qrCode: {
        message: "Read QrCode to start the session",
      },
      contacts: {
        title: "Contacts",
        toasts: {
          deleted: "Contact deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          importTitlte: "Import contacts",
          deleteMessage:
            "Are you sure you want to delete this contact? All related tickets will be lost.",
          importMessage: "Do you want to import all contacts from the phone?",
        },
        buttons: {
          import: "Import Contacts",
          add: "Add Contact",
        },
        table: {
          name: "Name",
          whatsapp: "WhatsApp",
          email: "Email",
          actions: "Actions",
        },
        openTicketExists: "Opening ticket already exists."
      },
      contactModal: {
        title: {
          add: "Add contact",
          edit: "Edit contact",
        },
        form: {
          mainInfo: "Contact details",
          extraInfo: "Additional information",
          name: "Name",
          number: "Whatsapp number",
          email: "Email",
          extraName: "Field name",
          extraValue: "Value",
        },
        buttons: {
          addExtraInfo: "Add information",
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Contact saved successfully.",
      },
      terms: {
        title: "Tags",
        toasts: {
          deleted: "Term deleted sucessfully!",
        },
        searchPlaceholder: "Search ...",
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure you want to delete this term? All related terms will be lost.",
        },
        buttons: {
          add: "Add Term",
        },
        table: {
          name: "Name",
          slug: "Slug",
          taxonomy: "Taxonomy",
          description: "Description",
          count: "Count",
          actions: "Actions",
        },
      },
      termModal: {
        title: {
          add: "Add term",
          edit: "Edit term",
        },
        form: {
          mainInfo: "Term details",
          name: "Name",
          slug: "Slug",
          taxonomy: "Taxonomy",
          description: "Description",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Term saved successfully.",
      },
      quickAnswersModal: {
        title: {
          add: "Add Quick Reply",
          edit: "Edit Quick Answer",
        },
        form: {
          shortcut: "Shortcut",
          message: "Quick Reply",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "Quick Reply saved successfully.",
      },
      queueModal: {
        title: {
          add: "Add queue",
          edit: "Edit queue",
        },
        form: {
          name: "Name",
          color: "Color",
          greetingMessage: "Greeting Message",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
      },
      userModal: {
        title: {
          add: "Add user",
          edit: "Edit user",
        },
        form: {
          name: "Name",
          email: "Email",
          password: "Password",
          profile: "Profile",
        },
        buttons: {
          okAdd: "Add",
          okEdit: "Save",
          cancel: "Cancel",
        },
        success: "User saved successfully.",
      },
      chat: {
        noTicketMessage: "Select a ticket to start chatting.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "New",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Queues",
      },
      tickets: {
        toasts: {
          deleted: "The ticket you were on has been deleted.",
        },
        notification: {
          message: "Message from",
        },
        tabs: {
          open: { title: "Inbox" },
          closed: { title: "Resolved" },
          search: { title: "Search" },
        },
        search: {
          placeholder: "Search tickets and messages.",
        },
        buttons: {
          showAll: "All",
        },
      },
      transferTicketModal: {
        title: "Transfer Ticket",
        fieldLabel: "Type to search for users",
        fieldQueueLabel: "Transfer to queue",
        fieldQueuePlaceholder: "Please select a queue",
        noOptions: "No user found with this name",
        buttons: {
          ok: "Transfer",
          cancel: "Cancel",
        },
      },
      ticketsList: {
        pendingHeader: "Queue",
        assignedHeader: "Working on",
        noTicketsTitle: "Nothing here!",
        noTicketsMessage: "No tickets found with this status or search term.",
        buttons: {
          accept: "Accept",
          view: "View"
        },
      },
      newTicketModal: {
        title: "Create Ticket",
        fieldLabel: "Type to search for a contact",
        add: "Add",
        buttons: {
          ok: "Save",
          cancel: "Cancel",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Connections",
          tickets: "Tickets",
          contacts: "Contacts",
          quickAnswers: "Quick Answers",
          queues: "Queues",
          administration: "Administration",
          users: "Users",
          settings: "Settings",
          cmcImportMessages: "Import Messages",
          schedulerMessages: "Scheduler Messages",
          apiHelp: "API",
          webhooks: "Webhooks",
          automations: "Automations",
          terms: "Tags"
        },
        appBar: {
          user: {
            profile: "Profile",
            logout: "Logout",
          },

          userProfile: {
            labelName: "Name",
            labelEmail: "Email"
          }
        },
      },
      notifications: {
        noTickets: "No notifications.",
      },
      queues: {
        title: "Queues",
        table: {
          name: "Name",
          color: "Color",
          greeting: "Greeting message",
          actions: "Actions",
        },
        buttons: {
          add: "Add queue",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "Are you sure? It cannot be reverted! Tickets in this queue will still exist, but will not have any queues assigned.",
        },
      },
      queueSelect: {
        inputLabel: "Queues",
      },
      quickAnswers: {
        title: "Quick Answers",
        table: {
          shortcut: "Shortcut",
          message: "Quick Reply",
          actions: "Actions",
        },
        buttons: {
          add: "Add Quick Reply",
        },
        toasts: {
          deleted: "Quick Reply deleted successfully.",
        },
        searchPlaceholder: "Search...",
        confirmationModal: {
          deleteTitle: "Are you sure you want to delete this Quick Reply: ",
          deleteMessage: "This action cannot be undone.",
        },
      },
      users: {
        title: "Users",
        table: {
          name: "Name",
          email: "Email",
          profile: "Profile",
          actions: "Actions",
        },
        buttons: {
          add: "Add user",
        },
        toasts: {
          deleted: "User deleted sucessfully.",
        },
        confirmationModal: {
          deleteTitle: "Delete",
          deleteMessage:
            "All user data will be lost. Users' open tickets will be moved to queue.",
        },
      },
      settings: {
        success: "Settings saved successfully.",
        title: "Settings",
        settings: {
          userCreation: {
            name: "User creation",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          keepHistory: {
            name: "Keep history",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          apiToken: "Api Token",
          mainUrl: "Main URL",
          templateAutomationActionSendMessageToNumber: "Automation Template (Sending Message to number)"
        },
      },
      messagesList: {
        header: {
          assignedTo: "Assigned to:",
          buttons: {
            return: "Return",
            resolve: "Resolve",
            reopen: "Reopen",
            accept: "Accept",
            tags: "Tags",
            additionalItems: "Additionals"
          },
          chip: {
            widthoutQueue: "Widthout Queue"
          }
        },
      },
      messagesInput: {
        placeholderOpen: "Type a message or press ''/'' to use the registered quick responses",
        placeholderClosed: "Reopen or accept this ticket to send a message.",
        signMessage: "Sign",
      },
      contactDrawer: {
        header: "Contact details",
        buttons: {
          edit: "Edit contact",
        },
        extraInfo: "Other information",
      },
      ticketOptionsMenu: {
        delete: "Delete",
        transfer: "Transfer",
        markAsUnread: "Mark as Unread",
        confirmationModal: {
          title: "Delete ticket #",
          titleFrom: "from contact ",
          message: "Attention! All ticket's related messages will be lost.",
        },
        unreadMessagesModal: {
          title: "Mark as unread ticket #",
          message: "The ticket will be marked as unread.",
        },
        buttons: {
          delete: "Delete",
          cancel: "Cancel",
        },
        importMessages: "Import Messages",
        schedulerMessages: "Scheduler Messages",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      unreadMessagesModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancel",
        },
      },
      messageOptionsMenu: {
        delete: "Delete",
        reply: "Reply",
        confirmationModal: {
          title: "Delete message?",
          message: "This action cannot be reverted.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "There must be at lest one default WhatsApp connection.",
        ERR_NO_DEF_WAPP_FOUND:
          "No default WhatsApp found. Check connections page.",
        ERR_WAPP_NOT_INITIALIZED:
          "This WhatsApp session is not initialized. Check connections page.",
        ERR_WAPP_CHECK_CONTACT:
          "Could not check WhatsApp contact. Check connections page.",
        ERR_WAPP_INVALID_CONTACT: "This is not a valid whatsapp number.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "Could not download media from WhatsApp. Check connections page.",
        ERR_INVALID_CREDENTIALS: "Authentication error. Please try again.",
        ERR_SENDING_WAPP_MSG:
          "Error sending WhatsApp message. Check connections page.",
        ERR_DELETE_WAPP_MSG: "Couldn't delete message from WhatsApp.",
        ERR_OTHER_OPEN_TICKET:
          "There's already an open ticket for this contact.",
        ERR_SESSION_EXPIRED: "Session expired. Please login.",
        ERR_USER_CREATION_DISABLED:
          "User creation was disabled by administrator.",
        ERR_NO_PERMISSION: "You don't have permission to access this resource.",
        ERR_DUPLICATED_CONTACT: "A contact with this number already exists.",
        ERR_NO_SETTING_FOUND: "No setting found with this ID.",
        ERR_NO_CONTACT_FOUND: "No contact found with this ID.",
        ERR_NO_TICKET_FOUND: "No ticket found with this ID.",
        ERR_NO_USER_FOUND: "No user found with this ID.",
        ERR_NO_WAPP_FOUND: "No WhatsApp found with this ID.",
        ERR_CREATING_MESSAGE: "Error while creating message on database.",
        ERR_CREATING_TICKET: "Error while creating ticket on database.",
        ERR_FETCH_WAPP_MSG:
          "Error fetching the message in WhtasApp, maybe it is too old.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "This color is already in use, pick another one.",
        ERR_WAPP_GREETING_REQUIRED:
          "Greeting message is required if there is more than one queue.",
      },

      apiHelp: {
        title: "API - Help",
        apiTitle: "API - Help"
      },

      webhooks: {
        confirmationModal: {
          deleteTitle: "Remove Webhook ",
          deleteMessage: "Do you like remove webhook"
        },

        searchPlaceholder: "Search ...",

        table: {
          name: "Name",
          url: "URL",
          trigger: "Trigger",
          method: "Method",
          status: "Status",
          actions: "Actions"
        }
      },

      webhookModal: {
        title: { 
          edit: "Edit webhook",
          add: "Add webhook"
        },

        form: {
          mainInfo: "Webhook details",
          name: "Name",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Output Message",
          triggerMessageNotFromMe: "Input Message",
          status: "Status",
          statusEnabled: "Enabled",
          statusDisabled: "Disabled"
        },

        buttons: {
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        }
      },

      automations: {
        title: "Automations",

        confirmationModal: {
          deleteTitle: "Remove automation ",
          deleteMessage: "Do you like remove automation"
        },

        searchPlaceholder: "Search ...",

        table: {
          name: "Name",
          url: "URL",
          trigger: "Trigger",
          order: "Order",
          status: "Status",
          actions: "Actions"
        },

        toasts: {
          deleted: "Automation removed!"
        }
      },

      automationBots: {
        title: "Automations",

        confirmationModal: {
          deleteTitle: "Remove automation ",
          deleteMessage: "Do you like remove automation"
        },

        searchPlaceholder: "Search ...",

        table: {
          name: "Name",
          url: "URL",
          trigger: "Trigger",
          order: "Order",
          status: "Status",
          actions: "Actions"
        },

        toasts: {
          deleted: "Automation removed!"
        }
      },

      automationModal: {
        title: { 
          edit: "Edit automation",
          add: "Add automation"
        },

        form: {
          mainInfo: "Automation details",
          name: "Name",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Output Message",
          triggerMessageNotFromMe: "Input Message",
          status: "Status",
          statusEnabled: "Enabled",
          statusDisabled: "Disabled",
          order: "Order"
        },

        buttons: {
          addFilters: "Add Filter",
          addActions: "Add Action",
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        },

        conditional: {
          title: "Conditionals",
          originLabel: "Origen",
          contentMessage: "Content Message",
          contactName: "Contact Name",
          contactEmail: "Contact Email",
          contactNumber: "Contact Number",
          ticketQueue: "Ticket Queue",
          ticketStatus: "Ticket Status",
          widthoutQueue: "Widthout Queue",
          connection: "Connection",

          conditionalLabel: "Conditional",
          equal: "Equal",
          notEqual: "Not Equal",
          contain: "Contain",
          notContain: "Not Contain",
          startsWith: "Starts With",
          endsWith: "Ends With",
          notStartsWith: "Not Starts With",
          notEndsWith: "Not Ends With",
          regexp: "Regex",

          valueLabel: "Value",

          noRecentOutgoingMessage: "No recent outgoing message (2 min)",

          active: "Active",

          tags: "Tags",

          weekTimeInterval: "Week / Hour Interval",
          weekLabel: "Week",
          between: "Between / Hour",
          notBetween: "Not Between / Hour",
          fromTime: "From",
          toTime: "To",

          week: {
            sunday: "Sunday",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            weekdays: "Weekdays (Mon..Fri)",
            weekend: "Weekend (Sat..Sun)",
            any: "Any"
          }
        },

        actions: {
          title: "Actions",
          actionLabel: "Action",
          responseMessage: "Response Message",
          alterQueue: "Alter queue",
          alterOperator: "Alter Operator",
          alterStatus: "Alter Status",
          webhook: "Webhook",
          valueLabel: "Value",
          widthoutQueue: "Widthout Queue",
          addTag: "Add Tag",
          removeTag: "Delete Tag",
          sendMessageToNumber: "Send Message To Number"
        },

        status: {
          opened: "Opened",
          pending: "Pending",
          closed: "Closed"
        },

        success: "Successful registration automation!"
      },

      automationBotModal: {
        title: { 
          edit: "Edit automation",
          add: "Add automation"
        },

        form: {
          mainInfo: "Automation details",
          name: "Name",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Output Message",
          triggerMessageNotFromMe: "Input Message",
          status: "Status",
          statusEnabled: "Enabled",
          statusDisabled: "Disabled",
          order: "Order"
        },

        buttons: {
          addFilters: "Add Filter",
          addActions: "Add Action",
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        },

        conditional: {
          title: "Conditionals",
          originLabel: "Origen",
          contentMessage: "Content Message",
          contactName: "Contact Name",
          contactEmail: "Contact Email",
          contactNumber: "Contact Number",
          ticketQueue: "Ticket Queue",
          ticketStatus: "Ticket Status",
          widthoutQueue: "Widthout Queue",
          connection: "Connection",

          conditionalLabel: "Conditional",
          equal: "Equal",
          notEqual: "Not Equal",
          contain: "Contain",
          notContain: "Not Contain",
          startsWith: "Starts With",
          endsWith: "Ends With",
          notStartsWith: "Not Starts With",
          notEndsWith: "Not Ends With",
          regexp: "Regex",

          valueLabel: "Value",

          noRecentOutgoingMessage: "No recent outgoing message (2 min)",

          active: "Active",

          tags: "Tags",

          weekTimeInterval: "Week / Hour Interval",
          weekLabel: "Week",
          between: "Between / Hour",
          notBetween: "Not Between / Hour",
          fromTime: "From",
          toTime: "To",

          week: {
            sunday: "Sunday",
            monday: "Monday",
            tuesday: "Tuesday",
            wednesday: "Wednesday",
            thursday: "Thursday",
            friday: "Friday",
            saturday: "Saturday",
            weekdays: "Weekdays (Mon..Fri)",
            weekend: "Weekend (Sat..Sun)",
            any: "Any"
          }
        },

        actions: {
          title: "Actions",
          actionLabel: "Action",
          responseMessage: "Response Message",
          alterQueue: "Alter queue",
          alterOperator: "Alter Operator",
          alterStatus: "Alter Status",
          webhook: "Webhook",
          valueLabel: "Value",
          widthoutQueue: "Widthout Queue",
          addTag: "Add Tag",
          removeTag: "Delete Tag",
          sendMessageToNumber: "Send Message To Number"
        },

        status: {
          opened: "Opened",
          pending: "Pending",
          closed: "Closed"
        },

        success: "Successful registration automation!"
      },

      schedulerMessages: {
        buttons: {
          add: "Add Scheduler Messages"
        },

        confirmationModal: {
          deleteTitle: "Remove Scheduler Message ",
          deleteMessage: "Do you like remove scheduler message?"
        },

        searchPlaceholder: "Search ...",

        table: {
          broadcast: "Broadcast",
          title: "Scheduler Messages",
          contact: "Contato",
          number: "Whatsapp",
          body: "Message",
          mediaType: "Media Type",
          scheduledTo: "Scheduled",
          createdAt: "Created At",
          status: "Status",
          statusWaiting: "Waiting",
          statusSent: "Sent",
          actions: "Actions"
        },

        toasts: {
          deleted: "Schedule deleted!"
        }
      },

      schedulerMessagesModal: {
        title: { 
          edit: "Edit Scheduler Messages",
          add: "Add Scheduler Messages"
        },

        form: {
          body: "Enter message here ...",
          scheduledTo: "Scheduler To: ",
          scheduledToDate: "Scheduler To Date: ",
          scheduledToTime: "Scheduler To Time: "
        },

        buttons: {
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        },

        success: "Successful registration message!"
      },

      notesList: {
        notFound: "No existing note for ticket!",
        cancel: "Cancel",
        submit: "Submit",
        buildContent: {
          placeholder: "Enter the note ..."
        },
        linkHelp: "Language markup help manual."
      },
    },  
  },
};

export { messages };

import React, { useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import InfoOutlineIcon from "@material-ui/icons/InfoOutlined";
// import InputMask from 'react-input-mask';

// import { i18n } from "../../translate/i18n";

import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    IconButton,
    Popover
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    popover: {
        maxWidth: "50%",
        padding: "10px"
    },

    box: {
        margin: "10px"
    },

    typography: {
        padding: "0px 5px 10px",
        //maxHeight: "480px",
        //overflow: "auto"
    },

    pre: {
        //display: "block",
        //width: ""
        maxHeight: "490px",
        border: "1px solid #ccc",
        background: "#f9f9f9",
        overflow: "auto"
    },

    accordionDetails: {
        display: "block"
    },

    iconButton: {
        width: "16px",
        height: "16px",
        padding: "0px 0px",
        margin: "0px 0px",
        fontSize: "10px"
    },

    infoOutlineIcon: {
        display: "block",
        fontSize: "14px"
    }
}));


const CrmHelpPopover = () => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <span>
            <IconButton
                className={classes.iconButton}
                color="primary"
                size="small"
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}>
                <InfoOutlineIcon className={classes.infoOutlineIcon} />
            </IconButton>
            <Popover className={classes.popover}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Box className={classes.box}>
                    <h3>Templates de Variáveis para Respostas</h3>
                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        O aplicativo passa variáveis ​​para os modelos para manipulação. 
                        As variáveis ​​podem ter atributos ou elementos que
                        você também pode acessar. A representação visual de uma variável
                        depende muito da aplicação que a fornece.
                    </Typography>

                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        Use um ponto {"(.)"} para acessar atributos de uma variável {"("}métodos
                        ou propriedades de um objeto JSON ou itens de um array JSON{")"}:
                    </Typography>

                    <Typography className={classes.typography} sx={{ p: 2 }}>
                        Exemplo da utilização: <strong>{"{{ crm.client.company }}"}</strong>
                    </Typography>

                    <Accordion expanded={true}>
                        <AccordionSummary
                            // expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography className={classes.heading}>
                                <strong>Financeiro com {"{{ crm }}"}</strong>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails className={classes.accordionDetails}>
                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                Exemplo: 
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                <pre className={classes.pre}>{`{
    "client":{
        "userid":"38",
        "company":"Clickmidia Marketing LTDA",
        "vat":"032.391.870-0017",
        "phonenumber":"(12) 3209-8682",
        "country":"32",
        "city":"São josé dos Campos",
        "zip":"12236-500",
        "state":"SP",
        "address":"Rua Gisele Martins, 828",
        "website":"",
        "datecreated":"2017-10-02 11:07:29",
        "active":"1"
    },
    "contact":{
        "id":"38",
        "firstname":"Fernando",
        "lastname":"Rodrigues",
        "is_primary":"0",
        "email":"eduroges+clienteinterno@gmail.com",
        "phonenumber":"55",
        "title":"",
        "datecreated":"2017-10-02 11:14:13",
        "active":"1"
    },
    "custom_field":{
        "fieldto":"contacts",
        "name":"Whatsapp",
        "slug":"contacts_whatsapp",
        "type":"input",
        "value":"(12) 997179671"
    },
    "financial":{
        "hash":"d07a66e0ceaab456c4e17d5bce07a317",
        "url":"https://adm.clickmidia.com.br/gcm_app/invoices/list_by/d07a66e0ceaab456c4e17d5bce07a317",
        "inv_qtd":"0",
        "inv_total":"0,00"
    }
}`}</pre>
                            </Typography>

                            <Typography className={classes.typography} sx={{ p: 2 }}>
                                Exemplo da utilização da variável "crm" dentro do conteúdo da resposta: {'{{'} crm.client.company  {'}}'}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Popover>
        </span>
    );
};

export default CrmHelpPopover;
import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
//import Tickets from "../pages/Tickets/";
import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
//import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
// import Contacts from "../pages/Contacts/";
// import QuickAnswers from "../pages/QuickAnswers/";
// import Queues from "../pages/Queues/";
// import ApiHelp from "../pages/ApiHelp/";
// import Webhooks from "../pages/Webhooks/";
// import Automations from "../pages/Automations/";
import AutomationBots from "../pages/AutomationBots/";
// import SchedulerMessages from "../pages/SchedulerMessages/";
// import CMCImportMessages from "../pages/CMCImportMessages";
// import Terms from "../pages/Terms"
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import EvolutionApiConnections from "../pages/EvolutionApiConnection";

const Routes = () => {
    return (
        <BrowserRouter>
            <AuthProvider>
                <Switch>
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/signup" component={Signup} />
                    <WhatsAppsProvider>
                        <LoggedInLayout>
                            <Route exact path="/" component={Dashboard} isPrivate />
                            {/* <Route
                                exact
                                path="/tickets/:ticketId?"
                                component={Tickets}
                                isPrivate
                            /> */}
                            {/* <Route
                                exact
                                path="/connections"
                                component={Connections}
                                isPrivate
                            /> */}
                            {/* <Route exact path="/contacts" component={Contacts} isPrivate /> */}
                            <Route exact path="/users" component={Users} isPrivate />
                            {/* <Route
                                exact
                                path="/quickAnswers"
                                component={QuickAnswers}
                                isPrivate
                            /> */}
                            <Route exact path="/settings" component={Settings} isPrivate />
                            {/* <Route exact path="/help/api" component={ApiHelp} isPrivate /> */}
                            {/* <Route exact path="/automations" component={Automations} isPrivate /> */}
                            <Route exact path="/automation-bots" component={AutomationBots} isPrivate />
                            <Route exact path="/evolution-api-connections" component={EvolutionApiConnections} isPrivate />
                            {/* <Route exact path="/webhooks" component={Webhooks} isPrivate /> */}
                            {/* <Route exact path="/scheduler-messages" component={SchedulerMessages} isPrivate /> */}
                            {/* <Route exact path="/Queues" component={Queues} isPrivate /> */}
                            {/* <Route exact path="/cmc-import-messages" component={CMCImportMessages} isPrivate /> */}
                            {/* <Route exact path="/terms" component={Terms} isPrivate /> */}
                        </LoggedInLayout>
                    </WhatsAppsProvider>
                </Switch>
                <ToastContainer autoClose={3000} />
            </AuthProvider>
        </BrowserRouter>
    );
};

export default Routes;

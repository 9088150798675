/**
 * @var {string}
 */
export const primary = "#1d7293";

/**
 * @var {string}
 */
export const secondary = "#4f8da5";

/**
 * @var {string}
 */
export const success = "#27b43d";

/**
 * @var {string}
 */
export const warning = "#eab226";

/**
 * @var {string}
 */
export const danger = "#981a00";

/**
 * @var {string}
 */
export const light = "#f1f1f1";

/**
 * @var {string}
 */
export const dark = "#242424";

/**
 * @var {string}
 */
export const white = "#ffffff";
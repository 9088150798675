import React, { useState, useEffect, useCallback } from "react";
import openSocket from "../../services/socket-io";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import { FormControl, FormLabel, Grid } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(8, 8, 3),
    },

    paper: {
        width: "100%",
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,

    },

    settingOption: {
        width: "100%",
        flex: "1",
        marginTop: "5px"
    },

    margin: {
        margin: theme.spacing(1),
    }
}));

const Settings = () => {
    const classes = useStyles();
    const [settings, setSettings] = useState([]);
    const [mainUrl, setMainUrl] = useState("");
    const [
        templateAutomationActionSendMessageToNumber, 
        setTemplateAutomationActionSendMessageToNumber
    ] = useState("");

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const {data} = await api.get("/settings");
                setSettings(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const socket = openSocket();

        socket.on("settings", data => {
            if (data.action === "update") {
                setSettings(prevState => {
                    const aux = [...prevState];
                    const settingIndex = aux.findIndex(s => s.key === data.setting.key);
                    aux[settingIndex].value = data.setting.value;
                    return aux;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const getSettingValue = useCallback(key => {
        const {value} = settings.find(s => s.key === key);
        return value;
    }, [settings]);

    useEffect(() => {
        const fetchSession = async () => {
            if (settings && settings.length > 0) {
                if (settings.find(s => s.key === "mainUrl")) {
                    setMainUrl(getSettingValue("mainUrl"));
                }

                if (settings.find(s => s.key === "templateAutomationActionSendMessageToNumber")) {
                    setTemplateAutomationActionSendMessageToNumber(
                        getSettingValue("templateAutomationActionSendMessageToNumber")
                    );
                }
            }
        };

        fetchSession();
    }, [settings, getSettingValue]);

    const handleChangeSetting = async e => {
        let selectedValue = e.target.value;
        let settingKey = e.target.name;

        // Apenas para a configuração de URL principal.
        if (settingKey === "mainUrl") {
            selectedValue = e.target.value.replace(/(\/)$/, "")
        }

        if (selectedValue !== getSettingValue(settingKey)) {
            try {
                await api.put(`/settings/${settingKey}`, {
                    value: selectedValue
                });
                toast.success(i18n.t("settings.success"));
            } catch (err) {
                toastError(err);
            }
        }
    };

    const handleChangeMainUrl = async e => {
        const value = e.target.value;
        setMainUrl(value);
    };

    const handleChangeTemplateAutomationActionSendMessageToNumber = async e => {
        const value = e.target.value;
        setTemplateAutomationActionSendMessageToNumber(value);
    };

    return (
            <div className={classes.root}>

                <Container className={classes.container} maxWidth="md">

                    <MainContainer variant="h3" gutterBottom>
                        <MainHeader style={{ padding: "20px 30px" }}>
				            <Title>
					            <SettingsIcon fontSize="small" />&nbsp;{i18n.t("settings.title")}
				            </Title>
			            </MainHeader>
                    </MainContainer>

                    <Grid container spacing={2}>

                        {/* mainUrl */}
                        <Grid container item xs={12} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>{i18n.t("settings.settings.mainUrl")}</strong>
                                    </FormLabel>
                                    <TextField
                                        id="mainUrl"
                                        name="mainUrl"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder="Ex.: https://www.cmchat.com.br"
                                        fullWidth
                                        onChange={handleChangeMainUrl}
                                        onBlur={handleChangeSetting}
                                        value={mainUrl}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>

                        {/* userApiToken */}
                        <Grid container item xs={4} md={12}>
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>{i18n.t("settings.settings.apiToken")}</strong>
                                    </FormLabel>
                                    <TextField
                                        id="api-token-setting"
                                        readOnly
                                        //label="Token Api"
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        value={settings && settings.length > 0 && getSettingValue("userApiToken")}
                                        />
                                </FormControl>
                            </Paper>
                        </Grid>

                        {/* userCreation */}
                        <Grid container item xs={4} md={6} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>{i18n.t("settings.settings.userCreation.name")}</strong>
                                    </FormLabel>
                                    <Select
                                        margin="dense"
                                        variant="outlined"
                                        native
                                        id="userCreation-setting"
                                        name="userCreation"
                                        value={
                                            settings && settings.length > 0 && getSettingValue("userCreation")
                                        }
                                        className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                        >
                                        <option value="enabled">
                                            {i18n.t("settings.settings.userCreation.options.enabled")}
                                        </option>
                                        <option value="disabled">
                                            {i18n.t("settings.settings.userCreation.options.disabled")}
                                        </option>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>

                        {/* keepHistory */}
                        <Grid container item xs={4} md={6}>
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>{i18n.t("settings.settings.keepHistory.name")}</strong>
                                    </FormLabel>
                                    <Select
                                        margin="dense"
                                        variant="outlined"
                                        native
                                        id="keepHistory-setting"
                                        name="keepHistory"
                                        value={
                                            settings && settings.length > 0 && getSettingValue("keepHistory")
                                        }
                                        className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                        >
                                        <option value="enabled">
                                            {i18n.t("settings.settings.keepHistory.options.enabled")}
                                        </option>
                                        <option value="disabled">
                                            {i18n.t("settings.settings.keepHistory.options.disabled")}
                                        </option>
                                    </Select>
                                </FormControl>
                            </Paper>
                        </Grid>

                        {/* templateAutomationActionSendMessageToNumber */}
                        {/* <Grid container item xs={12} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        {i18n.t("settings.settings.templateAutomationActionSendMessageToNumber")}
                                    </FormLabel>
                                    <TextField
                                        id="templateAutomationActionSendMessageToNumber"
                                        name="templateAutomationActionSendMessageToNumber"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        multiline={true}
                                        minRows={5}
                                        onChange={handleChangeTemplateAutomationActionSendMessageToNumber}
                                        onBlur={handleChangeSetting}
                                        value={templateAutomationActionSendMessageToNumber}
                                    />

                                    <p>
                                        Segue abaixo as variaveis para uso no template de mensagem na automação de envio para um número expecífico:
                                        <br />
                                        <strong>{
                                            "{{ number }} {{ name }} {{ email }} {{ message }} {{ ticketUrl }}"
                                        }</strong>
                                    </p>
                                </FormControl>
                            </Paper>
                        </Grid> */}
                    </Grid>

                    <MainContainer variant="h3" gutterBottom>
                        <MainHeader style={{ padding: "20px 30px" }}>
                            <Title>
                                <SettingsIcon fontSize="small" />&nbsp;Perfex CRM
                            </Title>
                        </MainHeader>
                    </MainContainer>


                    <Grid container spacing={2}>
                        {/* Perfex CRM */}
                        <Grid container item xs={12} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>Perfex CRM URL:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="perfexCrmUrl"
                                        name="perfexCrmUrl"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder="Ex.: https://www.cmchat.com.br"
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("perfexCrmUrl")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>


                    <MainContainer variant="h3" gutterBottom>
                        <MainHeader style={{ padding: "20px 30px" }}>
                            <Title>
                                <SettingsIcon fontSize="small" />&nbsp;Chatwoot
                            </Title>
                        </MainHeader>
                    </MainContainer>
                    
                    <Grid container spacing={2}>

                        {/* Perfex CRM */}
                        <Grid container item xs={6} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>Chatwoot URL:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="chatwootUrl"
                                        name="chatwootUrl"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder="Ex.: https://www.cmchat.com.br"
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("chatwootUrl")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>

                        <Grid container item xs={6} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>Chatwoot Token:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="chatwootToken"
                                        name="chatwootToken"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("chatwootToken")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>

                    <MainContainer variant="h3" gutterBottom>
                        <MainHeader style={{ padding: "20px 30px" }}>
                            <Title>
                                <SettingsIcon fontSize="small" />&nbsp;Open AI
                            </Title>
                        </MainHeader>
                    </MainContainer>

                    <Grid container spacing={2}>
                        
                        {/* Perfex CRM */}
                        <Grid container item xs={12} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>OpenAI URL:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="openaiUrl"
                                        name="openaiUrl"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("openaiUrl")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>

                        <Grid container item xs={6} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>OpenAI API Key:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="openaiApiKey"
                                        name="openaiApiKey"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("openaiApiKey")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>

                        <Grid container item xs={6} >
                            <Paper className={classes.paper}>
                                <FormControl
                                    variant="outlined"
                                    className={classes.formControl}
                                    margin="dense"
                                    fullWidth={true}
                                >
                                    <FormLabel>
                                        <strong>OpenAI Time Check:</strong>
                                    </FormLabel>
                                    <TextField
                                        id="openaiCheckRunStatus"
                                        name="openaiCheckRunStatus"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder=""
                                        fullWidth
                                        value={
                                            settings && settings.length > 0 && getSettingValue("openaiCheckRunStatus")
                                        }
                                        // className={classes.settingOption}
                                        onChange={handleChangeSetting}
                                    />
                                </FormControl>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            </div>
            );
};

export default Settings;

import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
// import { Badge } from "@material-ui/core";

// import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
// import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import CastConnectedOutinedIcon from "@material-ui/icons/CastConnectedOutlined";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
// import CenterFocusWeakIcon from "@material-ui/icons/CenterFocusWeak";
// import DeviceHubIcon from '@material-ui/icons/DeviceHub';
// import WhatsAppIcon from "@material-ui/icons/WhatsApp";
// import SyncAltIcon from "@material-ui/icons/SyncAlt";
// import SubjectIcon from '@material-ui/icons/Subject';
// import ScheduleIcon from '@material-ui/icons/Schedule';
// import ViewModuleIcon from '@material-ui/icons/ViewModule';
// import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { Can } from "../components/Can";

import { makeStyles } from "@material-ui/core";
import * as Pallete from "./Pallete";

const useStyles = makeStyles((theme) => ({
    listSubheader: {
        color: Pallete.white,
        paddingLeft: "10px",
        marginTop: "20px",
        fontWeight: "800"
    },
    iconWhite: {
        color: Pallete.white
    }
}));

function ListItemLink(props) {
    const { icon, primary, to, className } = props;

    const renderLink = React.useMemo(() => React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
    )), [to]);

    return (
        <li>
            <ListItem button component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    );
}

const MainListItems = (props) => {
    const { drawerClose } = props;
    const { whatsApps } = useContext(WhatsAppsContext);
    const { user } = useContext(AuthContext);
    const [connectionWarning, setConnectionWarning] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter((whats) => {
                    return (
                        whats.status === "qrcode" ||
                        whats.status === "PAIRING" ||
                        whats.status === "DISCONNECTED" ||
                        whats.status === "TIMEOUT" ||
                        whats.status === "OPENING"
                    );
                });
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true);
                } else {
                    setConnectionWarning(false);
                }
            }
        }, 2000);
        return () => clearTimeout(delayDebounceFn);
    }, [whatsApps]);

  return (
        <div onClick={drawerClose}>
            <ListSubheader inset className={classes.listSubheader}>
                Geral
            </ListSubheader>

            <Divider />
            
            <ListItemLink
                to="/"
                primary="Dashboard"
                icon={<DashboardOutlinedIcon className={classes.iconWhite} />}
            />

            {/* <ListItemLink
                to="/connections"
                primary={i18n.t("mainDrawer.listItems.connections")}
                icon={
                <Badge badgeContent={connectionWarning ? "!" : 0} color="error" overlap="rectangular">
                    <SyncAltIcon className={classes.iconWhite} />
                </Badge>
                }
            /> */}

            {/* <ListItemLink
                to="/tickets"
                primary={i18n.t("mainDrawer.listItems.tickets")}
                icon={<WhatsAppIcon className={classes.iconWhite} />}
            /> */}

            {/* <ListItemLink
                to="/contacts"
                primary={i18n.t("mainDrawer.listItems.contacts")}
                icon={<ContactPhoneOutlinedIcon className={classes.iconWhite} />}
            /> */}

            {/* <ListItemLink
                to="/quickAnswers"
                primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                icon={<QuestionAnswerOutlinedIcon className={classes.iconWhite} />}
            /> */}

            {/* <ListItemLink
                to="/cmc-import-messages"
                primary={i18n.t("mainDrawer.listItems.cmcImportMessages")}
                icon={<SubjectIcon className={classes.iconWhite} />}
            /> */}

            {/* <ListItemLink
                to="/scheduler-messages"
                primary={i18n.t("mainDrawer.listItems.schedulerMessages")}
                icon={<ScheduleIcon className={classes.iconWhite} />}
            /> */}

            {/* <ListItemLink
                to="/terms"
                primary={i18n.t("mainDrawer.listItems.terms")}
                icon={<LocalOfferIcon className={classes.iconWhite} />}
            /> */}

            <ListItemLink
                to="/automation-bots"
                primary={i18n.t("mainDrawer.listItems.automations")}
                icon={<AccountTreeOutlinedIcon className={classes.iconWhite} />}
            />

            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <>
                        {/* <Divider /> */}
                        
                        {/* <ListSubheader inset className={classes.listSubheader}>
                            Admin
                        </ListSubheader> */}

                        {/* <ListItemLink
                            to="/help/api"
                            primary={i18n.t("mainDrawer.listItems.apiHelp")}
                            icon={<DeviceHubIcon className={classes.iconWhite} />}
                        /> */}

                        {/* <ListItemLink
                            to="/webhooks"
                            primary={i18n.t("mainDrawer.listItems.webhooks")}
                            icon={<CenterFocusWeakIcon className={classes.iconWhite} />}
                        /> */}

                        <ListItemLink
                            to="/evolution-api-connections"
                            primary={"Evolution API"}
                            icon={<CastConnectedOutinedIcon className={classes.iconWhite} />}
                        />

                        <ListItemLink
                            to="/users"
                            primary={i18n.t("mainDrawer.listItems.users")}
                            icon={<PeopleAltOutlinedIcon className={classes.iconWhite} />}
                        />
                        
                        {/* <ListItemLink
                            to="/queues"
                            primary={i18n.t("mainDrawer.listItems.queues")}
                            icon={<ViewModuleIcon className={classes.iconWhite} />}
                        /> */}
                        
                        <ListItemLink
                            to="/settings"
                            primary={i18n.t("mainDrawer.listItems.settings")}
                            icon={<SettingsOutlinedIcon className={classes.iconWhite} />}
                        />
                    </>
                )}
            />
        </div>
    );
};

export default MainListItems;
import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "../../services/socket-io";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { makeStyles, styled } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

import IconButton from "@material-ui/core/IconButton";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditIcon from "@material-ui/icons/Edit";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";

import api from "../../services/api";
import AutomationBotModal from "../../components/AutomationBotModal";
import ConfirmationModal from "../../components/ConfirmationModal/";

import { i18n } from "../../translate/i18n";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import { TableContainer } from "@material-ui/core";

const reducer = (state, action) => {
    if (action.type === "LOAD_AUTOMATIONS") {
        const automationBots = action.payload;
        const newAutomationBots = [];

        automationBots.forEach((automation) => {
            const automationIndex = state.findIndex((w) => w.id === automation.id);
            if (automationIndex !== -1) {
                state[automationIndex] = automation;
            } else {
                newAutomationBots.push(automation);
            }
        });

        return [...state, ...newAutomationBots];
    }

    if (action.type === "UPDATE_AUTOMATIONS") {
        const automation = action.payload;
        const automationIndex = state.findIndex((w) => w.id === automation.id);

        if (automationIndex !== -1) {
            state[automationIndex] = automation;
            return [...state];
        } else {
            return [automation, ...state];
        }
    }

    if (action.type === "DELETE_AUTOMATION") {
        const automationId = action.payload;
        const automationIndex = state.findIndex((w) => w.id === automationId);
        if (automationIndex !== -1) {
            state.splice(automationIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const AutomationBots = () => {
    const classes = useStyles();
    const history = useHistory();

    const { user } = useContext(AuthContext);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [searchParam, setSearchParam] = useState("");
    const [automationBots, dispatch] = useReducer(reducer, []);
    const [selectedAutomationId, setSelectedAutomationId] = useState(null);
    const [automationBotModalOpen, setAutomationBotModalOpen] = useState(false);
    const [deletingAutomation, setDeletingAutomation] = useState(null);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [hasMore, setHasMore] = useState(false);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchAutomationBots = async () => {
                try {
                    const { data } = await api.get("/automation-bots/", {
                        params: { searchParam, pageNumber },
                    });
                    dispatch({ type: "LOAD_AUTOMATIONS", payload: data.automations });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
        
            fetchAutomationBots();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber]);

    useEffect(() => {
        const socket = openSocket();
        socket.on("automation", (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ 
                    type: "UPDATE_AUTOMATIONS", 
                    payload: data.automation 
                });
            }

            if (data.action === "delete") {
                dispatch({ 
                    type: "DELETE_AUTOMATION", 
                    payload: +data.automationId 
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleOpenAutomationBotModal = () => {
        setSelectedAutomationId(null);
        setAutomationBotModalOpen(true);
    };

    const handleCloseAutomationBotModal = () => {
        setSelectedAutomationId(null);
        setAutomationBotModalOpen(false);
    };

    const hadleEditAutomation = (automationId) => {
        setSelectedAutomationId(automationId);
        setAutomationBotModalOpen(true);
    };

    const handleDeleteAutomation = async (automationId) => {
        try {
            await api.delete(`/automation-bots/${automationId}`);
            toast.success(i18n.t("automationBots.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingAutomation(null);
        setSearchParam("");
        setPageNumber(1);
    };

    const handleimportAutomation = async () => {
        try {
            await api.post("/automation-bots/import");
            history.go(0);
        } catch (err) {
            toastError(err);
        }
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    return (
        <MainContainer className={classes.mainContainer}>
            <AutomationBotModal
                open={automationBotModalOpen}
                onClose={handleCloseAutomationBotModal}
                aria-labelledby="form-dialog-title"
                automationId={selectedAutomationId}
            ></AutomationBotModal>

            <ConfirmationModal
                title={
                    deletingAutomation   
                        ? `${i18n.t("automationBots.confirmationModal.deleteTitle")} ${
                            deletingAutomation.name
                        }?`
                        : `${i18n.t("automationBots.confirmationModal.importTitlte")}`
                }
                open={confirmOpen}
                onClose={setConfirmOpen}
                onConfirm={(e) =>
                    deletingAutomation
                    ? handleDeleteAutomation(deletingAutomation.id)
                    : handleimportAutomation()
                }
            >
                {deletingAutomation
                ? `${i18n.t("automationBots.confirmationModal.deleteMessage")}`
                : `${i18n.t("automationBots.confirmationModal.importMessage")}`}
            </ConfirmationModal>
            
            <MainHeader style={{ padding: "20px 30px" }}>
                <Title>
                    <AccountTreeOutlinedIcon /> {i18n.t("automationBots.title")}
                </Title>
                <MainHeaderButtonsWrapper>
                    <TextField
                        placeholder={i18n.t("automationBots.searchPlaceholder")}
                        type="search"
                        value={searchParam}
                        onChange={handleSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                <SearchIcon style={{ color: "gray" }} />
                                </InputAdornment>
                            ), 
                        }}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleOpenAutomationBotModal}
                    >
                        {i18n.t("Adicionar Automation")}
                    </Button>
                </MainHeaderButtonsWrapper>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}
            >
                <TableContainer component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell padding="checkbox" />
                                <TableCell>
                                    <strong>
                                        {i18n.t("automationBots.table.name")}
                                    </strong>
                                </TableCell>
                                <TableCell>
                                    <strong>
                                        Tipo
                                    </strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>
                                        {i18n.t("automationBots.table.order")}
                                    </strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>
                                        {i18n.t("automationBots.table.status")}
                                    </strong>
                                </TableCell>
                                <TableCell align="center">
                                    <strong>
                                        {i18n.t("automationBots.table.actions")}
                                    </strong>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {automationBots?.map((automation) => (
                                    <StyledTableRow key={automation.id}>
                                        <TableCell>
                                            <AccountTreeOutlinedIcon color="primary" size="small" />
                                        </TableCell>
                                        <TableCell>{automation.name}</TableCell>
                                        <TableCell>
                                            {(automation.type === "router") ? "Roteador" : "Automação"}
                                        </TableCell>
                                        <TableCell align="center">{automation.order}</TableCell>
                                        <TableCell align="center">
                                            {(automation.status === 1) ?  "Ativo" : "Inativo"}
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                size="small"
                                                color="primary"
                                                onClick={() => hadleEditAutomation(automation.id)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <Can
                                                role={user.profile}
                                                perform="automations-page:deleteAutomation"
                                                yes={() => (
                                                    <IconButton
                                                        size="small"
                                                        color="secondary"
                                                        onClick={(e) => {
                                                            setConfirmOpen(true);
                                                            setDeletingAutomation(automation);
                                                        }}
                                                    >
                                                        <DeleteOutlineIcon />
                                                    </IconButton>
                                                )}
                                            />
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                            </>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </MainContainer>
    );
};

export default AutomationBots;
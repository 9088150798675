const messages = {
  es: {
    translations: {
      signup: {
        title: "Registro",
        toasts: {
          success:
            "¡El usuario ha sido creado satisfactoriamente! ¡Ahora inicia sesión!",
          fail: "Error creando el usuario. Verifica la data reportada.",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Regístrate",
          login: "¿Ya tienes una cuenta? ¡Inicia sesión!",
        },
      },
      login: {
        title: "Inicio de Sesión",
        form: {
          email: "Correo Electrónico",
          password: "Contraseña",
        },
        buttons: {
          submit: "Ingresa",
          register: "¿No tienes cuenta? ¡Regístrate!",
        },
      },
      auth: {
        toasts: {
          success: "¡Inicio de sesión exitoso!",
        },
      },
      dashboard: {
        charts: {
          perDay: {
            title: "Tickets hoy: ",
          },
        },
        messages: {
          inAttendance: {
            title: "En servicio"
          },
          waiting: {
            title: "Esperando"
          },
          closed: {
            title: "Finalizado"
          }
        }
      },
      connections: {
        title: "Conexiones",
        toasts: {
          deleted:
            "¡La conexión de WhatsApp ha sido borrada satisfactoriamente!",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage: "¿Estás seguro? Este proceso no puede ser revertido.",
          disconnectTitle: "Desconectar",
          disconnectMessage: "Estás seguro? Deberá volver a leer el código QR",
        },
        buttons: {
          add: "Agrega WhatsApp",
          disconnect: "Desconectar",
          tryAgain: "Inténtalo de nuevo",
          qrcode: "QR CODE",
          newQr: "Nuevo QR CODE",
          connecting: "Conectando",
        },
        toolTips: {
          disconnected: {
            title: "No se pudo iniciar la sesión de WhatsApp",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y vuelva a intentarlo o solicite un nuevo código QR",
          },
          qrcode: {
            title: "Esperando la lectura del código QR",
            content:
              "Haga clic en el botón 'CÓDIGO QR' y lea el Código QR con su teléfono celular para iniciar la sesión",
          },
          connected: {
            title: "Conexión establecida",
          },
          timeout: {
            title: "Se perdió la conexión con el teléfono celular",
            content:
              "Asegúrese de que su teléfono celular esté conectado a Internet y que WhatsApp esté abierto, o haga clic en el botón 'Desconectar' para obtener un nuevo código QR",
          },
        },
        table: {
          name: "Nombre",
          status: "Estado",
          lastUpdate: "Última Actualización",
          default: "Por Defecto",
          actions: "Acciones",
          session: "Sesión",
        },
      },
      whatsappModal: {
        title: {
          add: "Agrega WhatsApp",
          edit: "Edita WhatsApp",
          farewellMessage: "Mensaje de despedida"
        },
        form: {
          name: "Nombre",
          default: "Por Defecto",
        },
        tab: {
          basic: "Basico",
          queue: "Fila",
          messages: "Mensajes"
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "WhatsApp guardado satisfactoriamente.",
      },
      whatsappConnectionsSelect: {
        inputLabel: "Importar tickets desde la conexión",
        info: "¡El campo anterior solo debe usarse cuando sea necesario importar tickets para la conexión que se está manipulando!",
        noConnection: "Sin conexión"
      },
      qrCode: {
        message: "Lée el código QR para empezar la sesión.",
      },
      contacts: {
        title: "Contactos",
        toasts: {
          deleted: "¡Contacto borrado satisfactoriamente!",
        },
        searchPlaceholder: "Buscar...",
        confirmationModal: {
          deleteTitle: "Borrar",
          importTitlte: "Importar contactos",
          deleteMessage:
            "¿Estás seguro que deseas borrar este contacto? Todos los tickets relacionados se perderán.",
          importMessage:
            "¿Quieres importar todos los contactos desde tu teléfono?",
        },
        buttons: {
          import: "Importar Contactos",
          add: "Agregar Contacto",
        },
        table: {
          name: "Nombre",
          whatsapp: "WhatsApp",
          email: "Correo Electrónico",
          actions: "Acciones",
        },
        openTicketExists: "El ticket de apertura ya existe."
      },
      contactModal: {
        title: {
          add: "Agregar contacto",
          edit: "Editar contacto",
        },
        form: {
          mainInfo: "Detalles del contacto",
          extraInfo: "Información adicional",
          name: "Nombre",
          number: "Número de Whatsapp",
          email: "Correo Electrónico",
          extraName: "Nombre del Campo",
          extraValue: "Valor",
        },
        buttons: {
          addExtraInfo: "Agregar información",
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Contacto guardado satisfactoriamente.",
      },
      terms: {
        title: "Tags",
        toasts: {
          deleted: "Etiqueta eliminada con éxito!",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle: "Remover",
          deleteMessage:
            "¿Está seguro de que desea eliminar esta etiqueta? Todas las etiquetas relacionadas se perderán.",
        },
        buttons: {
          add: "Añadir Etiqueta",
        },
        table: {
          name: "Nombre",
          slug: "Slug",
          taxonomy: "Taxonomía",
          description: "Descripción",
          count: "Contar",
          actions: "Acciones",
        },
      },
      termModal: {
        title: {
          add: "Añadir Etiqueta",
          edit: "Editar Etiqueta",
        },
        form: {
          mainInfo: "Detalles da Etiqueta",
          name: "Nombre",
          slug: "Slug",
          taxonomy: "Taxonomía",
          description: "Descripción",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Etiqueta guardada con éxito",
      },
      quickAnswersModal: {
        title: {
          add: "Agregar respuesta rápida",
          edit: "Editar respuesta rápida",
        },
        form: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Respuesta rápida guardada correctamente.",
      },
      queueModal: {
        title: {
          add: "Agregar cola",
          edit: "Editar cola",
        },
        form: {
          name: "Nombre",
          color: "Color",
          greetingMessage: "Mensaje de saludo",
        },
        buttons: {
          okAdd: "Añadir",
          okEdit: "Ahorrar",
          cancel: "Cancelar",
        },
      },
      userModal: {
        title: {
          add: "Agregar usuario",
          edit: "Editar usuario",
        },
        form: {
          name: "Nombre",
          email: "Correo Electrónico",
          password: "Contraseña",
          profile: "Perfil",
        },
        buttons: {
          okAdd: "Agregar",
          okEdit: "Guardar",
          cancel: "Cancelar",
        },
        success: "Usuario guardado satisfactoriamente.",
      },
      chat: {
        noTicketMessage: "Selecciona un ticket para empezar a chatear.",
      },
      ticketsManager: {
        buttons: {
          newTicket: "Nuevo",
        },
      },
      ticketsQueueSelect: {
        placeholder: "Linhas",
      },
      tickets: {
        toasts: {
          deleted: "El ticket en el que estabas ha sido borrado.",
        },
        notification: {
          message: "Mensaje de",
        },
        tabs: {
          open: { title: "Bandeja" },
          closed: { title: "Resueltos" },
          search: { title: "Buscar" },
        },
        search: {
          placeholder: "Buscar tickets y mensajes.",
        },
        buttons: {
          showAll: "Todos",
        },
      },
      transferTicketModal: {
        title: "Transferir Ticket",
        fieldLabel: "Escriba para buscar usuarios",
        fieldQueueLabel: "Transferir a la cola",
        fieldQueuePlaceholder: "Seleccione una cola",
        noOptions: "No se encontraron usuarios con ese nombre",
        buttons: {
          ok: "Transferir",
          cancel: "Cancelar",
        },
      },
      ticketsList: {
        pendingHeader: "Cola",
        assignedHeader: "Trabajando en",
        noTicketsTitle: "¡Nada acá!",
        noTicketsMessage:
          "No se encontraron tickets con este estado o término de búsqueda",
        buttons: {
          accept: "Acceptar",
          view: "Abrir"
        },
      },
      newTicketModal: {
        title: "Crear Ticket",
        fieldLabel: "Escribe para buscar un contacto",
        add: "Añadir",
        buttons: {
          ok: "Guardar",
          cancel: "Cancelar",
        },
      },
      mainDrawer: {
        listItems: {
          dashboard: "Dashboard",
          connections: "Conexiones",
          tickets: "Tickets",
          contacts: "Contactos",
          quickAnswers: "Respuestas rápidas",
          queues: "Linhas",
          administration: "Administración",
          users: "Usuarios",
          settings: "Configuración",
          cmcImportMessages: "Importar Mensajes",
          schedulerMessages: "Mensajes Programados",
          apiHelp: "API",
          webhooks: "Webhooks",
          automations: "Automatización",
          terms: "Tags"
        },
        appBar: {
          user: {
            profile: "Perfil",
            logout: "Cerrar Sesión",
          },

          userProfile: {
            labelName: "Nombre",
            labelEmail: "Email"
          }
        },
      },
      notifications: {
        noTickets: "Sin notificaciones.",
      },
      queues: {
        title: "Linhas",
        table: {
          name: "Nombre",
          color: "Color",
          greeting: "Mensaje de saludo",
          actions: "Comportamiento",
        },
        buttons: {
          add: "Agregar cola",
        },
        confirmationModal: {
          deleteTitle: "Eliminar",
          deleteMessage:
            "¿Estás seguro? ¡Esta acción no se puede revertir! Los tickets en esa cola seguirán existiendo, pero ya no tendrán ninguna cola asignada.",
        },
      },
      queueSelect: {
        inputLabel: "Linhas",
      },
      quickAnswers: {
        title: "Respuestas rápidas",
        table: {
          shortcut: "Atajo",
          message: "Respuesta rápida",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar respuesta rápida",
        },
        toasts: {
          deleted: "Respuesta rápida eliminada correctamente",
        },
        searchPlaceholder: "Buscar ...",
        confirmationModal: {
          deleteTitle:
            "¿Está seguro de que desea eliminar esta respuesta rápida?",
          deleteMessage: "Esta acción no se puede deshacer.",
        },
      },
      users: {
        title: "Usuarios",
        table: {
          name: "Nombre",
          email: "Correo Electrónico",
          profile: "Perfil",
          actions: "Acciones",
        },
        buttons: {
          add: "Agregar usuario",
        },
        toasts: {
          deleted: "Usuario borrado satisfactoriamente.",
        },
        confirmationModal: {
          deleteTitle: "Borrar",
          deleteMessage:
            "Toda la información del usuario se perderá. Los tickets abiertos de los usuarios se moverán a la cola.",
        },
      },
      settings: {
        success: "Configuración guardada satisfactoriamente.",
        title: "Configuración",
        settings: {
          userCreation: {
            name: "Creación de usuarios",
            options: {
              enabled: "Habilitado",
              disabled: "Deshabilitado",
            },
          },
          keepHistory: {
            name: "Mantener la historia",
            options: {
              enabled: "Enabled",
              disabled: "Disabled",
            },
          },
          apiToken: "Api Token",
          mainUrl: "URL Principal",
          templateAutomationActionSendMessageToNumber: "Plantilla de automatización (envío de mensaje a número)"
        },
      },
      messagesList: {
        header: {
          assignedTo: "Asignado a:",
          buttons: {
            return: "Devolver",
            resolve: "Resolver",
            reopen: "Reabrir",
            accept: "Aceptar",
            tags: "Tags",
            additionalItems: "Adicionales"
          },
          chip: {
            widthoutQueue: "Sin fila"
          }
        },
      },
      messagesInput: {
        placeholderOpen: "Escriba un mensaje o presione '' / '' para usar las respuestas rápidas registradas",
        placeholderClosed:
          "Vuelva a abrir o acepte este ticket para enviar un mensaje.",
        signMessage: "Firmar",
      },
      contactDrawer: {
        header: "Detalles del contacto",
        buttons: {
          edit: "Editar contacto",
        },
        extraInfo: "Otra información",
      },
      ticketOptionsMenu: {
        delete: "Borrar",
        transfer: "Transferir",
        markAsUnread: "Marcar Como No Leído",
        confirmationModal: {
          title: "¿Borrar ticket #",
          titleFrom: "del contacto ",
          message:
            "¡Atención! Todos los mensajes Todos los mensajes relacionados con el ticket se perderán.",
        },
        unreadMessagesModal: {
          title: "Marcar como ticket no leído #",
          message: "El ticket se marcará como no leído.",
        },
        buttons: {
          delete: "Borrar",
          cancel: "Cancelar",
        },
        importMessages: "Importar Mensajes",
        schedulerMessages: "Programar Mensajes",
      },
      confirmationModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      unreadMessagesModal: {
        buttons: {
          confirm: "Ok",
          cancel: "Cancelar",
        },
      },
      messageOptionsMenu: {
        delete: "Borrar",
        reply: "Responder",
        confirmationModal: {
          title: "¿Borrar mensaje?",
          message: "Esta acción no puede ser revertida.",
        },
      },
      backendErrors: {
        ERR_NO_OTHER_WHATSAPP:
          "Debe haber al menos una conexión de WhatsApp predeterminada.",
        ERR_NO_DEF_WAPP_FOUND:
          "No se encontró WhatsApp predeterminado. Verifique la página de conexiones.",
        ERR_WAPP_NOT_INITIALIZED:
          "Esta sesión de WhatsApp no ​​está inicializada. Verifique la página de conexiones.",
        ERR_WAPP_CHECK_CONTACT:
          "No se pudo verificar el contacto de WhatsApp. Verifique la página de conexiones.",
        ERR_WAPP_INVALID_CONTACT: "Este no es un número de whatsapp válido.",
        ERR_WAPP_DOWNLOAD_MEDIA:
          "No se pudieron descargar los medios de WhatsApp. Verifique la página de conexiones.",
        ERR_INVALID_CREDENTIALS: "Error de autenticación. Vuelva a intentarlo.",
        ERR_SENDING_WAPP_MSG:
          "Error al enviar el mensaje de WhatsApp. Verifique la página de conexiones.",
        ERR_DELETE_WAPP_MSG: "No se pudo borrar el mensaje de WhatsApp.",
        ERR_OTHER_OPEN_TICKET: "Ya hay un ticket abierto para este contacto.",
        ERR_SESSION_EXPIRED: "Sesión caducada. Inicie sesión.",
        ERR_USER_CREATION_DISABLED:
          "La creación de usuarios fue deshabilitada por el administrador.",
        ERR_NO_PERMISSION: "No tienes permiso para acceder a este recurso.",
        ERR_DUPLICATED_CONTACT: "Ya existe un contacto con este número.",
        ERR_NO_SETTING_FOUND:
          "No se encontró ninguna configuración con este ID.",
        ERR_NO_CONTACT_FOUND: "No se encontró ningún contacto con este ID.",
        ERR_NO_TICKET_FOUND: "No se encontró ningún ticket con este ID.",
        ERR_NO_USER_FOUND: "No se encontró ningún usuario con este ID.",
        ERR_NO_WAPP_FOUND: "No se encontró WhatsApp con este ID.",
        ERR_CREATING_MESSAGE: "Error al crear el mensaje en la base de datos.",
        ERR_CREATING_TICKET: "Error al crear el ticket en la base de datos.",
        ERR_FETCH_WAPP_MSG:
          "Error al obtener el mensaje en WhtasApp, tal vez sea demasiado antiguo.",
        ERR_QUEUE_COLOR_ALREADY_EXISTS:
          "Este color ya está en uso, elija otro.",
        ERR_WAPP_GREETING_REQUIRED:
          "El mensaje de saludo es obligatorio cuando hay más de una cola.",
      },

      apiHelp: {
        title: "API - Ajuda",
        apiTitle: "API - Ajuda"
      },

      webhooks: {
        confirmationModal: {
          deleteTitle: "Remover Webhook ",
          deleteMessage: "Te gustaría remover el webhook "
        },

        searchPlaceholder: "Búsqueda ...",
        
        table: {
          name: "Nombre",
          url: "URL",
          trigger: "Generar",
          method: "Método",
          status: "Status",
          actions: "Acciones"
        }
      },

      webhookModal: {
        title: { 
          edit: "Editar webhook",
          add: "Adicionar webhook"
        },

        form: {
          mainInfo: "Detalles de webhook",
          name: "Nombre",
          url: "URL",
          trigger: "Generar (Hook)",
          triggerMessageFromMe: "Mensage de Salida",
          triggerMessageNotFromMe: "Mensage de Entrada",
          status: "Status",
          statusEnabled: "Active",
          statusDisabled: "Inactive"
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Agregar",
          okEdit: "Editar"
        }
      },

      automations: {
        title: "Automaciones",

        confirmationModal: {
          deleteTitle: "Remove automation ",
          deleteMessage: "Do you like remove automation"
        },

        searchPlaceholder: "Search ...",

        table: {
          name: "Name",
          url: "URL",
          trigger: "Trigger",
          order: "Ordenar",
          status: "Status",
          actions: "Actions"
        },

        toasts: {
          deleted: "Automatización eliminada!"
        }
      },

      automationBots: {
        title: "Automaciones",

        confirmationModal: {
          deleteTitle: "Remove automation ",
          deleteMessage: "Do you like remove automation"
        },

        searchPlaceholder: "Search ...",

        table: {
          name: "Name",
          url: "URL",
          trigger: "Trigger",
          order: "Ordenar",
          status: "Status",
          actions: "Actions"
        },

        toasts: {
          deleted: "Automatización eliminada!"
        }
      },

      automationModal: {
        title: { 
          edit: "Editar automatización",
          add: "Agregar automatizacion"
        },

        form: {
          mainInfo: "Detalles de la automatización",
          name: "Name",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Output Message",
          triggerMessageNotFromMe: "Input Message",
          status: "Status",
          statusEnabled: "Enabled",
          statusDisabled: "Disabled",
          order: "Ordenar"
        },

        buttons: {
          addFilters: "Agregar Filtro",
          addActions: "Agregar  Accion",
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        },

        conditional: {
          title: "Condicionales",
          originLabel: "Origen",
          contentMessage: "Conteudo de la Mensaje",
          contactName: "Nombre del Contacto",
          contactEmail: "Email del Contacto",
          contactNumber: "Number de Contact",
          ticketQueue: "Fila del Ticket",
          ticketStatus: "Status del Ticket",
          widthoutQueue: "Sin fila",
          connection: "Connexión",

          conditionalLabel: "Condicional",
          equal: "Igual",
          notEqual: "No Igual",
          contain: "Contiene",
          notContain: "No Contiene",
          startsWith: "Empieza Con",
          endsWith: "Termina Con",
          notStartsWith: "No Empieza Con",
          notEndsWith: "No Termina Con",
          regexp: "Regex",

          valueLabel: "Valor",

          noRecentOutgoingMessage: "Ningún mensaje saliente reciente (2 min)",

          active: "Activar",

          tags: "Tags",

          weekLabel: "Semana",
          weekTimeInterval: "Semana / Intervalo Hora",
          between: "Intervalo / Hora",
          notBetween: "Afuera del Inervalo / Hora",
          fromTime: "De",
          toTime: "Hasta",

          week: {
            sunday: "Domingo",
            monday: "Lunes",
            tuesday: "Martes",
            wednesday: "Miércules",
            thursday: "Jueves",
            friday: "Viernes",
            saturday: "Sábado",
            weekdays: "Dias da semana (Lun..Vie)",
            weekend: "Final de Semana (Sab..Dom)",
            any: "Cualquiera"
          }
        },

        actions: {
          title: "Aciones",
          actionLabel: "Accion",
          responseMessage: "Mensaje de Respuesta",
          alterQueue: "Alterar Fila",
          alterOperator: "Alterar Operador",
          alterStatus: "Alterar Status",
          webhook: "Webhook",
          valueLabel: "Valor",
          widthoutQueue: "Sin fila",
          addTag: "Anãdir Etiqueta",
          removeTag: "Eliminar Etiqueta",
          sendMessageToNumber: "Enviar mensaje a número"
        },

        status: {
          opened: "Abrió",
          pending: "Pendiente",
          closed: "Cerrado"
        },

        success: "¡Automatización de registro exitosa!"
      },

      automationBotModal: {
        title: { 
          edit: "Editar automatización",
          add: "Agregar automatizacion"
        },

        form: {
          mainInfo: "Detalles de la automatización",
          name: "Name",
          url: "URL",
          trigger: "Trigger (Hook)",
          triggerMessageFromMe: "Output Message",
          triggerMessageNotFromMe: "Input Message",
          status: "Status",
          statusEnabled: "Enabled",
          statusDisabled: "Disabled",
          order: "Ordenar"
        },

        buttons: {
          addFilters: "Agregar Filtro",
          addActions: "Agregar  Accion",
          cancel: "Cancel",
          okAdd: "Add",
          okEdit: "Edit"
        },

        conditional: {
          title: "Condicionales",
          originLabel: "Origen",
          contentMessage: "Conteudo de la Mensaje",
          contactName: "Nombre del Contacto",
          contactEmail: "Email del Contacto",
          contactNumber: "Number de Contact",
          ticketQueue: "Fila del Ticket",
          ticketStatus: "Status del Ticket",
          widthoutQueue: "Sin fila",
          connection: "Connexión",

          conditionalLabel: "Condicional",
          equal: "Igual",
          notEqual: "No Igual",
          contain: "Contiene",
          notContain: "No Contiene",
          startsWith: "Empieza Con",
          endsWith: "Termina Con",
          notStartsWith: "No Empieza Con",
          notEndsWith: "No Termina Con",
          regexp: "Regex",

          valueLabel: "Valor",

          noRecentOutgoingMessage: "Ningún mensaje saliente reciente (2 min)",

          active: "Activar",

          tags: "Tags",

          weekLabel: "Semana",
          weekTimeInterval: "Semana / Intervalo Hora",
          between: "Intervalo / Hora",
          notBetween: "Afuera del Inervalo / Hora",
          fromTime: "De",
          toTime: "Hasta",

          week: {
            sunday: "Domingo",
            monday: "Lunes",
            tuesday: "Martes",
            wednesday: "Miércules",
            thursday: "Jueves",
            friday: "Viernes",
            saturday: "Sábado",
            weekdays: "Dias da semana (Lun..Vie)",
            weekend: "Final de Semana (Sab..Dom)",
            any: "Cualquiera"
          }
        },

        actions: {
          title: "Aciones",
          actionLabel: "Accion",
          responseMessage: "Mensaje de Respuesta",
          alterQueue: "Alterar Fila",
          alterOperator: "Alterar Operador",
          alterStatus: "Alterar Status",
          webhook: "Webhook",
          valueLabel: "Valor",
          widthoutQueue: "Sin fila",
          addTag: "Anãdir Etiqueta",
          removeTag: "Eliminar Etiqueta",
          sendMessageToNumber: "Enviar mensaje a número"
        },

        status: {
          opened: "Abrió",
          pending: "Pendiente",
          closed: "Cerrado"
        },

        success: "¡Automatización de registro exitosa!"
      },

      schedulerMessages: {
        buttons: {
          add: "Agregar mensajes del programador"
        },

        confirmationModal: {
          deleteTitle: "Eliminar mensaje del programador",
          deleteMessage: "¿Te gusta eliminar el mensaje del programador?"
        },

        searchPlaceholder: "Búsqueda ...",

        table: {
          broadcast: "Broadcast",
          title: "Mensajes del programador",
          body: "Mensaje",
          mediaType: "Tipo de medio",
          scheduledTo: "Programada",
          createdAt: "Creado en",
          status: "Status", 
          statusWaiting: "Esperando",
          statusSent: "Sent",
          actions: "Acciones"
        },

        toasts: {
          deleted: "Horario eliminado!"
        }
      },

      schedulerMessagesModal: {
        title: { 
          edit: "Editar Mensajes del Programador",
          add: "Agregar Mensajes del Programador"
        },

        form: {
          body: "Ingrese el mensaje aquí...",
          scheduledTo: "Programador para: ",
          scheduledToDate: "Fecha de programacion: ",
          scheduledToTime: "Tiempo programado: "
        },

        buttons: {
          cancel: "Cancelar",
          okAdd: "Agregar",
          okEdit: "Editar"
        },

        success: "¡Mensaje de registro exitoso!"
      },

      notesList: {
        notFound: "No hay nota existente para el ticket:",
        cancel: "Cancelar",
        submit: "Enviar",
        buildContent: {
          placeholder: "Escriba la nota ..."
        },
        linkHelp: "Manual de linguaje markdown."
      },
    },
  },
};

export { messages };
